import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Paper,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  FormControl,
  Button,
  Modal,
  Grid,
} from "@material-ui/core";
import {
  pointToLineDistance,
  lineString,
  lineDistance,
  bearing,
  point,
} from "@turf/turf";
import Map from "../components/Map";
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { FirebaseContext } from "common";
import GoogleMapsAutoComplete from "../components/GoogleMapsAutoComplete";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import * as geolib from "geolib";
import { IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getContinent } from "components/getContinent";
import OfflineImage  from "../assets/img/carImagee-fotor-20241002134223.png"


const useStyles = makeStyles((theme) => ({
  ...styles,
  modal: {
    display: "flex",
    padding: theme.spacing(1),
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  inputRtl: {
    "& label": {
      right: 25,
      left: "auto",
    },
    "& legend": {
      textAlign: "right",
      marginRight: 18,
    },
  },
}));

const Dashboard = (props) => {
  const { api } = useContext(FirebaseContext);
  const { MinutesPassed } = api;

  const [mylocation, setMylocation] = useState(null);
  const [locations, setLocations] = useState([]);
  const [ActiveCarrier, setActiveCarrier] = React.useState("");

  const [settings, setSettings] = useState({});
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const usersdata = useSelector((state) => state.usersdata);
  const bookinglistdata = useSelector((state) => state.bookinglistdata);
  const settingsdata = useSelector((state) => state.settingsdata);
  const auth = useSelector((state) => state.auth);
  const trailers = useSelector((state) => state.trailertypes.trailers);
  const classes = useStyles();

  const [trailerType, setTrailerType] = useState("Select Trailer Type");
  const trailertypes = useSelector((state) => state.trailertypes.trailers);
  const [trailerTypeData, setTrailerTypeData] = useState([]);

  const [pickupAddress, setPickupAddress] = useState(null);
  const [dropAddress, setDropAddress] = useState(null);
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [selectedTrailerDetails, setSelectedTrailerDetails] = useState(null);
  const rootRef = useRef(null);

  const emptyRoutePostinglistdataActive = useSelector(
    (state) => state.emptyRoutePostinglistdata.active
  );

  useEffect(() => {
    if (mylocation == null) {
      navigator.geolocation.getCurrentPosition(
        (position) =>
          setMylocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          })
        //error => console.log(error)
      );
    }
  }, [mylocation]);

  useEffect(() => {
    if (settingsdata.settings) {
      setSettings(settingsdata.settings);
    }
  }, [settingsdata.settings]);

  const {
    fetchSharedCarriersOfBroker,
    fetchUsersOnce,
    DispatcherVsAgentFetch,
    fetchRoutes,
    allCarriersDriverLocation
  } = api;
  const [CarriersData, setCarriersData] = useState([]);
  const [isRedirected, setisRedirected] = useState(false);
  const sharedCariers = useSelector((state) => state.usersdata.carrierBrokers);
  const  dispatcherAgents = useSelector(state => state.usersdata. dispatcherAgents);
  const locationdata = useSelector((state) => state.locationdata);

  const dispatch = useDispatch();

  const  [allDriversLocation,setAllDriversLocation]  = useState([])
  useEffect(() => {
    if(auth?.info?.profile?.usertype  === 'fleetadmin'){
       dispatch(allCarriersDriverLocation(auth?.info?.uid));
    }else if(auth?.info?.profile?.carrierId){
      dispatch(allCarriersDriverLocation(auth?.info?.profile?.carrierId));
    }
 }, [dispatch, allCarriersDriverLocation,auth])
 
 useEffect(()=>{
  if(locationdata?.fetchDriverLocation){
    setAllDriversLocation(locationdata?.fetchDriverLocation)
  }
  else{
    setAllDriversLocation([])
  }
},[locationdata])


  useEffect(()=>{
    if(auth?.info?.profile?.usertype === 'fleetadmin'){
        dispatch(fetchRoutes(auth.info.uid));
    }
    else dispatch(fetchRoutes(auth.info.profile?.carrierId));
    
  },[dispatch,fetchRoutes,auth]);

  const location = useLocation();
  useEffect(() => {
    if (location.search) {
      const queryParams = new URLSearchParams(location.search);
      const carrier = queryParams.get("carrier");
      const redirected = queryParams.get("redirected");
      if (carrier) {
        let filterdData = CarriersData?.filter((e) => {
          return e?.value === carrier;
        });
      if (filterdData.length > 0) {
          setActiveCarrier(filterdData[0].value);
          setisRedirected(redirected)
        } else if (CarriersData?.length > 0) {
          setActiveCarrier(CarriersData[0].value);
          setisRedirected(false)
        }
      } else if (CarriersData?.length > 0) {
        setActiveCarrier(CarriersData[0].value);
        setisRedirected(false)
      }
    } else if (CarriersData?.length > 0) {
      setActiveCarrier(CarriersData[0].value);
      setisRedirected(false)
    }
  }, [location, CarriersData]);

  useEffect(() => {
    if(auth.info?.profile?.brokerId){
      dispatch(fetchSharedCarriersOfBroker({ brokerId: auth.info?.profile?.brokerId}));
      dispatch(DispatcherVsAgentFetch({shipperId:auth.info?.profile?.brokerId}))
    }
    else if(auth.info?.profile?.usertype === 'shipper'){
      dispatch(fetchSharedCarriersOfBroker({ brokerId: auth.info.uid }));   
      dispatch(DispatcherVsAgentFetch({shipperId:auth.info.uid}))
    }

    dispatch(fetchUsersOnce());
  }, [dispatch, fetchSharedCarriersOfBroker]);
  
  useEffect(() => {
     let generalCarriers={};

    if(auth?.info?.profile?.usertype !==  'fleetadmin' && !auth?.info?.profile?.carrierId){
      
        if(dispatcherAgents&&dispatcherAgents.length>0){
          dispatcherAgents?.map((e) => {
              if (e.isBrokerActive && e.aprovedByBroker && e.approved&& !generalCarriers.hasOwnProperty(e.id)) {
                    generalCarriers[e.id]=e
              }
          })
    
        }
        if(sharedCariers&&sharedCariers.length>0){
            sharedCariers?.map((e) => {
                if (e?.isCarrierActive && e.aprovedByBroker && e.approved&& !generalCarriers.hasOwnProperty(e.id)) {
                      generalCarriers[e.id]=e
                }
            })
    
        }
    }

     if(Object.values(generalCarriers).length>0){
      let datas = [];
        Object.values(generalCarriers)?.map((e) => {
            datas.push({
              value: e.id,
              label: `${e.firstName} ${e.lastName} (${e.usertype==='dispatcher'?'Dispatch Agency':e.carrierData?.legalName? e.carrierData?.legalName+" - "+e.carrierData?.dotNumber :"Carrier With No DOT Number"}) `,
            });
        });

      setCarriersData(datas);
     }
    
  }, [sharedCariers,dispatcherAgents]);


  useEffect(() => {
    if (usersdata.users) {
      const drivers = usersdata.users.filter(
        (user) =>
          user.usertype === "driver" &&
          ((user.fleetadmin === auth.info.uid &&
            auth.info.profile.usertype === "fleetadmin") ||
            auth.info.profile.usertype === "admin")
            );

      let locs = [];
      for (let i = 0; i < drivers.length; i++) {
         const driver_location = allDriversLocation.filter(e=>{
           return e.driverId === i
         })
         
         if (
          drivers[i].approved &&
          drivers[i].driverActiveStatus &&
          driver_location.length>0
        ) {
          let carImage;
          for (let j = 0; j < trailers?.length; j++) {
            if (trailers[j].name === drivers[i].trailerType) {
              carImage = trailers[j].image;
            }
          }
          locs.push({
            id: i,
            lat: driver_location[0].location.lat,
            lng: driver_location[0].location.lng,
            drivername: drivers[i].firstName + " " + drivers[i].lastName,
            carnumber: drivers[i].vehicleNumber,
            trailertype: drivers[i].trailerType,
            carImage: carImage,
          });
        }
      }

      setLocations(locs);
    }
  }, [usersdata.users, auth.info.profile, auth.info.uid, trailers]);
  

  function ExtractNecceseryDataFromEmptyRoute(emptyRoutes) {
    let minimizedRoute = [];
    for (const emptyRoute of emptyRoutes) {
      let singleEmptyRoute = {
        postDate: emptyRoute.postDate,
        routedate: emptyRoute.routedate,
        emptyRouteFrom: emptyRoute.emptyRouteFrom,
        emptyRouteTo: emptyRoute.emptyRouteTo,
        routeType: emptyRoute.routeType,
        distance: emptyRoute.distance,
        id: emptyRoute.id,
        routeRatePerMile:emptyRoute.routeRatePerMile
      };
      minimizedRoute.push(singleEmptyRoute);
    }

    return minimizedRoute;
  }

  let [GeneralMapData, setGeneralMapData] = useState(null);
  const [MapLocations, setMapLocations] = useState(null);

  const [filter, setFilter] = useState([]);
  const [reset, setReset] = useState(false);

  function generateRandomString() {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";

    for (let i = 0; i < 7; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }

    const randomNumber = Math.floor(Math.random() * 10000);
    const randomString = result + randomNumber.toString();

    return randomString;
  }

  // set data from redux to state based on board type
  function isSuperDispatcher(){
    return auth.info.profile.usertype==='dispatcher'&&auth.info.profile.independent
  }


  const [data, setData] = useState([]);
  useEffect(() => {
    if (emptyRoutePostinglistdataActive?.length > 0) {
      const sortedData = [...emptyRoutePostinglistdataActive];
      sortedData.sort((a, b) => {
        const dateA = new Date(a.routedate);
        const dateB = new Date(b.routedate);
        return dateA - dateB;
      });

      setData(sortedData);
    }
  }, [emptyRoutePostinglistdataActive]);

  const giveIdThenIwillGiveYouData=(id)=>{
    const targetUser =usersdata.users.filter(e=>{
     return e.id===id
  })
    if(targetUser.length>0) return targetUser[0]
    return null

}


  function isDriverInOneOfCarriers(){
     let sharedCariers= auth.info?.profile?.dispatcherId ? giveIdThenIwillGiveYouData(auth.info?.profile?.dispatcherId)?.SharedCarriers: auth.info?.profile?.SharedCarriers
     if(!sharedCariers||sharedCariers.length===0) return false
      let existedDrivers=usersdata.users.filter(user=>{
          let isDriverInSharedCarriers=sharedCariers.filter(carrieR=>{
              return  user.usertype === "driver"&&carrieR.id===user.fleetadmin&&carrieR.approved&&carrieR.aprovedByCarrier
          }).length>0
          return isDriverInSharedCarriers
      })
      return existedDrivers
  }


  const checkActiveUserHasDriverData=(fleetadmin,ActiveCarrier)=>{
    if(fleetadmin===ActiveCarrier) return true
    if(giveIdThenIwillGiveYouData(ActiveCarrier)?.usertype === 'dispatcher'&&giveIdThenIwillGiveYouData(ActiveCarrier)?.independent){
        if(giveIdThenIwillGiveYouData(ActiveCarrier).SharedCarriers&&giveIdThenIwillGiveYouData(ActiveCarrier).SharedCarriers.length>0){
           let isDriverInSharedCarriers=giveIdThenIwillGiveYouData(ActiveCarrier).SharedCarriers.filter(carrieR=>{
            return  carrieR.id===fleetadmin
            }).length>0
            return isDriverInSharedCarriers
        }
        return false
    }
    return false

  }
  useEffect(() => {
    const combinedDataMap = [];
    
    const drivers =(isSuperDispatcher()||auth.info?.profile?.dispatcherId)?isDriverInOneOfCarriers()
                  :usersdata.users.filter(
                  (user) =>{
                      return  user.usertype === "driver" &&user.approved&&user.emailVerified&&
                          ((user.fleetadmin === auth.info.uid &&
                          auth.info.profile.usertype === "fleetadmin") ||
                          auth.info.profile.usertype === "admin" ||
                          (user.fleetadmin === auth.info.profile?.carrierId &&
                          auth.info.profile.usertype === "dispatcher") ||
                          (checkActiveUserHasDriverData(user.fleetadmin,ActiveCarrier ) &&
                          (auth.info.profile.usertype === "shipper" || auth.info?.profile?.brokerId)))
                  }
                );
    if(drivers.length>0){
      for (const driver of drivers) {
        // if(getContinent(giveIdThenIwillGiveYouData(driver.fleetadmin)?.selectedcountry?.code)  !==  getContinent(auth.info?.profile?.selectedcountry?.code)){
        //   continue
        //  }
        if(!driver.approved||!driver.emailVerified){
          continue
         }
         const driver_location = allDriversLocation.filter(e=>{
          return e.driverId === driver?.id
        })
              
        let driverData = {
          TelegramId: driver?.TelegramId,
          firstName: driver?.firstName,
          lastName: driver?.lastName,
          id: driver?.id,
        };

        if (driver_location.length>0 && !driver.queue) {
          let combinedData = {
            id: generateRandomString(),
            location: driver_location[0]?.location||null,
            travelRadius: driver?.travelRadius,
            trailerType: driver?.trailerType,
            name: driver?.firstName + " " + driver?.lastName,
            queue: driver?.queue,
            driverActiveStatus:driver?.driverActiveStatus,
            driver: driverData,
            driverActiveStatus: driver?.driverActiveStatus,
            route: driver.location,
            routeType: "Available Capacity",
            fleetadmin: driver.fleetadmin,
            ratePerMile:driver.ratePerMile,
            availableWeight:driver?.availableWeight
          };
          combinedDataMap.push(combinedData);
        }
  
        if (driver?.returnHomeDate && driver.baseCity ) {
          if (
            moment(new Date(driver.returnHomeDate)).format("L") ===
              moment(new Date()).format("L") &&
            driver?.location
          ) {
            let combinedData = {
              id: generateRandomString(),
              location: driver?.location,
              travelRadius: driver?.travelRadius,
              trailerType: driver?.trailerType,
              name: driver?.firstName + " " + driver?.lastName,
              queue: driver?.queue,
              driverActiveStatus:driver?.driverActiveStatus,
              driver: driverData,
              driverActiveStatus: driver?.driverActiveStatus,
              route: driver.returnHomeDate,
              routeType: "Future Capacity",
              baseCity: driver.baseCity,
              fleetadmin: driver.fleetadmin,
              ratePerMile:driver.ratePerMile,
              availableWeight:driver?.availableWeight
            };
  
            combinedDataMap.push(combinedData);
          }
        }
  
        if (driver.frequentRoute) {
          let combinedData = {
            id: generateRandomString(),
            location: driver?.location,
            route: driver.frequentRoute,
            travelRadius: driver?.travelRadius,
            trailerType: driver?.trailerType,
            name: driver?.firstName + " " + driver?.lastName,
            queue: driver?.queue,
            driverActiveStatus:driver?.driverActiveStatus,
            driver: driverData,
            driverActiveStatus: driver?.driverActiveStatus,
            frequentRouteDates: driver.frequentRouteDates,
            routeType: "Preferred Lane",
            fleetadmin: driver.fleetadmin,
            ratePerMile:driver.frequentRouteratePerMile,
            availableWeight:driver?.availableWeight
          };
          combinedDataMap.push(combinedData);
        }

        let getEmptyRouteOfDriver = data.filter((e) => e.driver === driver.id);
        if (getEmptyRouteOfDriver?.length > 0) {
          for (const emptyRoutes of ExtractNecceseryDataFromEmptyRoute(
            getEmptyRouteOfDriver
          )) {
            let combinedData = {
              id: generateRandomString(),
              route: emptyRoutes,
              location: driver?.location,
              travelRadius: driver?.travelRadius,
              trailerType: driver?.trailerType,
              name: driver?.firstName + " " + driver?.lastName,
              queue: driver?.queue,
              driverActiveStatus:driver?.driverActiveStatus,
              driver: driverData,
              routeType: "Future Capacity",
              fleetadmin: driver.fleetadmin,
              ratePerMile:emptyRoutes.routeRatePerMile,
              availableWeight:driver?.availableWeight
            };
            combinedDataMap.push(combinedData);
          }
        }

      }
      setGeneralMapData(combinedDataMap);
    }
    else{
      setGeneralMapData([])
    }
  }, [
    usersdata.users,
    auth.info.profile,
    auth.info.uid,
    trailers,
    ActiveCarrier,
    data,
    allDriversLocation
  ]);


  function generateRandomId(length) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }

    return result;
  }

  const extractCityStateFromAddress = (address) => {
    if(!address) return ""
    const parts = address.split(", ");
    if (parts?.length !== 4) {
      return address;
    }
    const cityState = parts[parts.length - 2].split(" ");
    if (cityState?.length !== 2) {
      return address;
    }
    const city = parts[1];
    const state = cityState[0];

    return `${city}, ${state}`;
  };

  const onDateChange = (event) => {
    if (event.target.value) {
      const currentDate = new Date().getTime();
      const targetDate = new Date(event.target.value).getTime();
      if (targetDate > currentDate) setSelectedDate(event.target.value);
      else setSelectedDate(null)
    }
  };


  useEffect(() => {
    if (GeneralMapData&&GeneralMapData.length > 0) {
      const data =
        (pickupAddress && dropAddress) || selectedDate
          ? filter
          : GeneralMapData;
      let mapRoutes = [];
      for (let i = 0; i < data.length; i++) {
        

        let basicData = {
          location: data[i]?.location || null,
          baseCity: data[i]?.baseCity || null,
          trailerType: data[i].trailerType,
          route: data[i]?.route,
          routeType: data[i].routeType,
          driver: data[i]?.driver,
          id: data[i]?.id,
          travelRadius: data[i]?.travelRadius,
          frequentRouteDates: data[i]?.frequentRouteDates || null,
          fleetadmin: data[i].fleetadmin,
          ratePerMile:data[i].ratePerMile,
          availableWeight:data[i]?.availableWeight

        };

        if (data[i].routeType === "Available Capacity") {
          // line segment object pushed to locs

          const locationCityState = data[i].location
            ? extractCityStateFromAddress(data[i].location.add)
            : null;
          const randomId = generateRandomId(14);
          basicData["origin"] = {
            lat: data[i]?.route?.lat,
            lng: data[i]?.route?.lng,
            add: data[i]?.route?.add,
          };
          basicData["destination"] = null;
          mapRoutes.push({
            id: randomId,
            lat: data[i]?.location?.lat,
            lng: data[i]?.location?.lng,
            drivername: data[i].name,
            travelRadius: data[i].travelRadius,
            trailerType: data[i].trailerType,
            currentLocation: locationCityState,
            carImage:!data[i].driverActiveStatus? OfflineImage:"https://cdn-icons-png.flaticon.com/512/6492/6492616.png",
            type: data[i].routeType,
            canBeBooked: data[i]?.canBeBooked,
            basicData,
          });
        }

        if (
          data[i].routeType === "Future Capacity" &&
          data[i]?.route?.emptyRouteFrom
        ) {
          // line segment object pushed to locs

          const randomId = generateRandomId(8);
          const locationCityState = data[i].location
            ? extractCityStateFromAddress(data[i].location.add)
            : null;

          basicData["origin"] = data[i].route.emptyRouteFrom;
          basicData["destination"] = data[i].route.emptyRouteTo;

          mapRoutes.push({
            id: randomId,
            lineSegment: [
              {
                lat: data[i].route.emptyRouteFrom.lat,
                lng: data[i].route.emptyRouteFrom.lng,
              },
              {
                lat: data[i].route.emptyRouteTo.lat,
                lng: data[i].route.emptyRouteTo.lng,
              },
            ],
            drivername: data[i].name,
            trailerType: data[i].trailerType,
            routeType: data[i].route.routeType?.name,
            cityOne: data[i].route.emptyRouteFrom.add,
            cityTwo: data[i].route.emptyRouteTo.add,
            routeDate: data[i].route.routedate
              ? moment(data[i].route.routedate).format("LL")
              : null,
            currentLocation: locationCityState,
            color: "#748f92",
            type: data[i].routeType,
            canBeBooked: data[i]?.canBeBooked,
            basicData,
          });
        }

        if (data[i].routeType === "Preferred Lane") {
          // line segment object pushed to locs
          const randomId = generateRandomId(8);
          const locationCityState = data[i].location
            ? extractCityStateFromAddress(data[i].location.add)
            : null;

          basicData["origin"] = data[i].route.cityOne;
          basicData["destination"] = data[i].route.cityTwo;

          mapRoutes.push({
            id: randomId,
            lineSegment: [
              {
                lat: data[i].route.cityOne.lat,
                lng: data[i].route.cityOne.lng,
              },
              {
                lat: data[i].route.cityTwo.lat,
                lng: data[i].route.cityTwo.lng,
              },
            ],
            drivername: data[i].name,
            trailerType: data[i].trailerType,
            cityOne: data[i].route.cityOne.add,
            cityTwo: data[i].route.cityTwo.add,
            routeDate: data[i].frequentRouteDates?.join(", "),
            currentLocation: locationCityState,
            color: pageBackgroundColor(),
            type: data[i].routeType,
            canBeBooked: data[i]?.canBeBooked,
            basicData,
          });
        }

        if (
          data[i].routeType === "Future Capacity" &&
          data[i]?.location?.lat &&
          data[i]?.baseCity?.lat
        ) {
          // line segment object pushed to locs

          const randomId = generateRandomId(8);

          const locationCityState = data[i].location
            ? extractCityStateFromAddress(data[i].location.add)
            : null;

          basicData["origin"] = data[i]?.location;
          basicData["destination"] = data[i]?.baseCity;

          mapRoutes.push({
            id: randomId,
            lineSegment: [
              { lat: data[i].location.lat, lng: data[i].location.lng },
              { lat: data[i].baseCity.lat, lng: data[i].baseCity.lng },
            ],
            drivername: data[i].name,
            trailerType: data[i].trailerType,
            cityOne: locationCityState,
            cityTwo: data[i].baseCity.add,
            routeDate: data[i].returnHomeDate
              ? moment(data[i].returnHomeDate).format("LL")
              : null,
            currentLocation: locationCityState,
            color: "#748f92",
            type: data[i].routeType,
            canBeBooked: data[i]?.canBeBooked,
            basicData,
          });
        }
      }
      setMapLocations(mapRoutes);
    }
    else if(GeneralMapData&&GeneralMapData.length === 0){
      setMapLocations([])
    }
  }, [
    ActiveCarrier,
    GeneralMapData,
    filter,
    pickupAddress,
    dropAddress,
    trailerType,
    selectedDate,
  ]);
  

  
 const [centerOfMap,setCenterOfMap] = useState({ lat: 9.1450, lng: 40.4897 })

 const getUserLocation = () => {
  if (navigator.geolocation) {

    navigator.geolocation.getCurrentPosition(
      (position) => {
        setCenterOfMap({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      },
      (error) => {
        //console.error('Error getting user location:', error);
      }
    );
  } else {
    //console.error('Geolocation is not supported by this browser.');
  }
};


  useEffect(()=>{
  
    getUserLocation();
  },[])

  const [openAddDriverModal, setOpenAddDriverModal] = useState(false)

  useEffect(()=>{ 
       if (MapLocations?.length===0) {
         return setOpenAddDriverModal(true)
       }
       else return setOpenAddDriverModal(false)

  }, [ MapLocations?.length===0])

  const handleTrailerSelect = (event) => {
    setTrailerType(event.target.value);
    let trailerDetails = null;
    for (let i = 0; i < trailertypes.length; i++) {
      if (trailertypes[i].name === event.target.value) {
        trailerDetails = trailertypes[i];
        let instObj = { ...instructionData };

        if (Array.isArray(trailertypes[i].options)) {
          instObj.optionSelected = trailertypes[i].options[0];
          instObj.optionIndex = 0;
        }
        setInstructionData(instObj);
      }
    }
    setSelectedTrailerDetails(trailerDetails);
  };

  const [instructionData, setInstructionData] = useState({
    deliveryPerson: "",
    deliveryPersonPhone: "",
    loadDetails: "",
    pickUpInstructions: "",
    deliveryInstructions: "",
    shipperOffer: "",
  });

  useEffect(() => {
    if (trailertypes && trailertypes.length > 0) {
      setTrailerTypeData(trailertypes[0].routeTypes);
    } else {
      setTrailerTypeData([]);
    }
  }, [trailertypes]);

  function checkTrailerType(trailerType, driver) {
    return (
      trailerType === driver.trailerType ||
      trailerType === "Select Trailer Type"
    );
  }

  const clearFilterAndMatch = () => {
    setReset(true);
    setFilter([]);
    setDropAddress(null);
    setPickupAddress(null);
    setSelectedDate(null);
    setTrailerType("Select Trailer Type");
  };

  const handleChange = (event) => {
    setActiveCarrier(event.target.value);
    setFilter([]);
  };

  function calculateTotalDistance(route) {
    return geolib.getDistance(route[0], route[1]);
  }

  useEffect(() => {
    let tracking_data = [];
    if (GeneralMapData&&GeneralMapData.length > 0) {
      for (const data of GeneralMapData) {
        if (data?.route?.cityOne && data.route?.cityTwo) {
          let trackArray = {
            id: data.id,
            address: [
              {
                latitude: data.route.cityOne.lat,
                longitude: data.route.cityOne.lng,
              },
              {
                latitude: data.route.cityTwo.lat,
                longitude: data.route.cityTwo.lng,
              },
            ],
          };
          tracking_data.push(trackArray);
        } else if (data?.route?.emptyRouteTo && data?.route?.emptyRouteFrom) {
          let trackArray = {
            id: data.id,
            address: [
              {
                latitude: data.route.emptyRouteFrom.lat,
                longitude: data.route.emptyRouteFrom.lng,
              },
              {
                latitude: data.route.emptyRouteTo.lat,
                longitude: data.route.emptyRouteTo.lng,
              },
            ],
          };
          tracking_data.push(trackArray);
        } else if (
          data.routeType === "Future Capacity" &&
          !data?.route?.emptyRouteTo
        ) {
          const hostLocation = {
            latitude: data?.location?.lat,
            longitude: data?.location?.lng,
          };
          const dropLocation = {
            latitude: data.baseCity.lat,
            longitude: data.baseCity.lng,
          };
          let trackArray = {
            id: data.id,
            address: [hostLocation, dropLocation],
          };
          tracking_data.push(trackArray);
        }
      }
    }

    if (
      GeneralMapData&& GeneralMapData.length > 0 &&
      pickupAddress &&
      dropAddress &&
      !selectedDate
    ) {
      setReset(false);
      const pickupAddressObject = {
        latitude: pickupAddress.coords.lat,
        longitude: pickupAddress.coords.lng,
      };
      const dropAddressObject = {
        latitude: dropAddress.coords.lat,
        longitude: dropAddress.coords.lng,
      };

      const viableTruckRoutes = evaluateTruckRoutes(
        pickupAddressObject,
        dropAddressObject,
        tracking_data
      );
      const alwaysAvailebleRoutes = [];
      for (const data of GeneralMapData) {
        if (
          data.routeType === "Available Capacity" &&
          data.travelRadius &&
          dropAddress &&
          pickupAddress
        ) {
          let travelRadius = Number(data.travelRadius) * 1609.34;
          const hostLocation = {
            latitude: data.route.lat,
            longitude: data.route.lng,
          };
          // const dropLocation = {
          //   latitude: dropAddress.coords.lat,
          //   longitude: dropAddress.coords.lng,
          // };
          const pickLocation = {
            latitude: pickupAddress.coords.lat,
            longitude: pickupAddress.coords.lng,
          };

          const DistancePickFromLocation = calculateTotalDistance([
            hostLocation,
            pickLocation,
          ]);
          if (travelRadius >= DistancePickFromLocation)
            alwaysAvailebleRoutes.push({ ...data, canBeBooked: true });
        } else if (
          data.routeType === "Available Capacity" &&
          !data.travelRadius
        ) {
          alwaysAvailebleRoutes.push({ ...data, canBeBooked: true });
        }
      }

      let filteredDatas = [];
      for (const data of viableTruckRoutes) {
        let dataExist = GeneralMapData.filter((e) => {
          return e.id === data.id;
        });
        if (dataExist.length > 0) {
          if (
            trailerType === dataExist[0].trailerType ||
            trailerType === "Select Trailer Type"
          ) {
            filteredDatas.push({
              ...dataExist[0],
              canBeBooked: data?.canBeBooked,
            });
          }
        }
      }

      if (alwaysAvailebleRoutes.length > 0)
        setFilter([...alwaysAvailebleRoutes, ...filteredDatas]);
      else setFilter(filteredDatas);
    }

    if (selectedDate && !(pickupAddress && dropAddress)) {
      setReset(false);
      setFilter(Object.values(checkDateAndTrailerType(GeneralMapData)));
    }

    if (
      selectedDate &&
      GeneralMapData&&GeneralMapData.length > 0 &&
      pickupAddress &&
      dropAddress
    ) {
      setReset(false);
      const pickupAddressObject = {
        latitude: pickupAddress.coords.lat,
        longitude: pickupAddress.coords.lng,
      };
      const dropAddressObject = {
        latitude: dropAddress.coords.lat,
        longitude: dropAddress.coords.lng,
      };

      const viableTruckRoutes = evaluateTruckRoutes(
        pickupAddressObject,
        dropAddressObject,
        tracking_data
      );
      let filteredDatas = [];
      for (const data of viableTruckRoutes) {
        let dataExist = GeneralMapData.filter((e) => {
          return e.id === data.id;
        });
        if (dataExist.length > 0)
          filteredDatas.push({
            ...dataExist[0],
            canBeBooked: data?.canBeBooked,
          });
      }

      const alwaysAvailebleRoutes = [];
      for (const data of GeneralMapData) {
        if (
          data.routeType === "Available Capacity" &&
          data.travelRadius &&
          dropAddress &&
          pickupAddress
        ) {
          let travelRadius = Number(data.travelRadius) * 1609.34;
          const hostLocation = {
            latitude: data.route.lat,
            longitude: data.route.lng,
          };
          // const dropLocation = {
          //   latitude: dropAddress.coords.lat,
          //   longitude: dropAddress.coords.lng,
          // };
          const pickLocation = {
            latitude: pickupAddress.coords.lat,
            longitude: pickupAddress.coords.lng,
          };

          const DistancePickFromLocation = calculateTotalDistance([
            hostLocation,
            pickLocation,
          ]);
          if (travelRadius >= DistancePickFromLocation)
            alwaysAvailebleRoutes.push({ ...data, canBeBooked: true });
        } else if (
          data.routeType === "Available Capacity" &&
          !data.travelRadius
        ) {
          alwaysAvailebleRoutes.push({ ...data, canBeBooked: true });
        }
      }

      let dataReadyToBePushed = [];

      if(alwaysAvailebleRoutes.length>0&&Object.values( checkDateAndTrailerType(alwaysAvailebleRoutes)).length>0){
        for (const data of Object.values( checkDateAndTrailerType(alwaysAvailebleRoutes)) ){
          dataReadyToBePushed.push(data)
        }
       }

      if (
        filteredDatas.length > 0 &&
        Object.values(checkDateAndTrailerType(filteredDatas)).length > 0
      ) {
        for (const data of Object.values(
          checkDateAndTrailerType(filteredDatas)
        )) {
          dataReadyToBePushed.push(data);
        }
      }

      setFilter(dataReadyToBePushed);
    }

    if (
      GeneralMapData&&GeneralMapData.length > 0 &&
      trailerType &&
      !selectedDate &&
      !(pickupAddress && dropAddress)
    ) {
      const targetedDatas = {};
      setReset(false);
      for (const data of GeneralMapData) {
        if (
          checkTrailerType(trailerType, data) &&
          !targetedDatas.hasOwnProperty(data.id)
        ) {
          targetedDatas[data.id] = data;
        }
      }
      setFilter(Object.values(targetedDatas));
    }
  }, [pickupAddress, dropAddress, selectedDate, trailerType]);

  const pageBackgroundColor=()=>{
    if(auth?.info?.profile?.dashboardColor) return auth?.info?.profile?.dashboardColor
    if(giveIdThenIwillGiveYouData(auth?.info?.profile?.carrierId)?.dashboardColor) return giveIdThenIwillGiveYouData(auth?.info?.profile?.carrierId)?.dashboardColor
    return "#01aac1"
}

  const checkDateAndTrailerType=(data)=>{
    let filteredData = {};
    data.forEach((driver) => {

      if (selectedDate) {
        // if (!driver?.route?.lat) {
          const foundDate =
            driver?.frequentRouteDates &&
            driver?.frequentRouteDates?.length > 0 &&
            driver?.frequentRouteDates?.includes(
              moment(selectedDate).format("dddd")
            );
          
          if (foundDate) {
            if (checkTrailerType(trailerType, driver)&&!filteredData.hasOwnProperty(driver.id)) {
              filteredData[driver.id] = driver;
            }
          }
  
          if (
            driver?.route?.routedate &&
            moment(new Date(selectedDate)).format("L") ===
            moment(new Date(driver?.route?.routedate)).format("L")
          ) {
            if (checkTrailerType(trailerType, driver)&&!filteredData.hasOwnProperty(driver.id)) {
              filteredData[driver.id] = driver;
            }
          }
  
          if (
            (driver?.routeType === "Future Capacity" && !driver?.route?.emptyRouteFrom)&&
            moment(new Date(selectedDate)).format("L") ===
              moment(new Date(driver?.route)).format("L")
          ) {
            if (checkTrailerType(trailerType, driver)&&!filteredData.hasOwnProperty(driver.id)) {
              filteredData[driver.id] = driver;
            }
          }

          if(driver?.routeType === "Available Capacity" && moment(new Date(selectedDate)).isAfter(moment(new Date()).add(3, 'hours')) &&  moment(new Date(selectedDate)).format("L") === moment(new Date()).format("L")){
            if (checkTrailerType(trailerType, driver) && !filteredData.hasOwnProperty(driver.id)) {
              filteredData[driver.id] = driver;
            }
          }


        // } else {
        //   if (checkTrailerType(trailerType, driver)&&!filteredData.hasOwnProperty(driver.id)) {
        //     filteredData[driver.id] = driver;
        //   }
        // }
      }
    });

    return filteredData

  }

  // Function to evaluate viability of truck routes based on user input
  function evaluateTruckRoutes(userPickUp, userDropOff, truckRoutes) {
    function calculateBearing(point1, point2) {
      return geolib.getRhumbLineBearing(point1, point2);
    }

    function isDirectionAligned(userRoute, truckRoute) {
      const userAvgBearing = calculateAverageBearing(userRoute);
      const truckAvgBearing = calculateAverageBearing(truckRoute);

      const firstCondition= Math.abs(userAvgBearing - truckAvgBearing) < 15
      const secondCondition = Math.abs(userAvgBearing - truckAvgBearing) > 165
      const thirdCondition = Math.abs(userAvgBearing - truckAvgBearing) < 195
      
      return firstCondition || (secondCondition && thirdCondition);
    }

    function calculateAverageBearing(route) {
      let totalBearing = 0;
      for (let i = 0; i < route.length - 1; i++) {
        totalBearing += calculateBearing(route[i], route[i + 1]);
      }
      return totalBearing / (route.length - 1);
    }

    function calculateProximityScore(userRoute, truckRoute) {
      let minDistance = Infinity;
      const weightFactors = [1, 0.8, 0.6]; // Higher weight closer to start/end
      for (let i = 0; i < userRoute.length; i++) {
        for (const point of truckRoute) {
          const distance = geolib.getDistance(userRoute[i], point);
          minDistance = Math.min(minDistance, distance * weightFactors[i]);
        }
      }

      // Adjust threshold as needed (50 km here)
      const threshold = 50 * 1000;
      return minDistance <= threshold;
    }

    function isRouteSegmentConsistent(userBearing, truckSegment) {
      const truckSegmentBearing = calculateBearing(
        truckSegment[0],
        truckSegment[1]
      );
      return 90 < Math.abs(userBearing - truckSegmentBearing) <= 30; // Allow some tolerance
    }

    function isRouteConsistent(userRoute, truckRoute) {
      const userBearing = calculateBearing(userRoute[0], userRoute[1]);
      for (let i = 0; i < truckRoute.length - 1; i++) {
        const truckSegment = truckRoute.slice(i, i + 2); // Consider each segment
        if (!isRouteSegmentConsistent(userBearing, truckSegment)) {
          return false;
        }
      }
      return true;
    }

    const userRoute = [userPickUp, userDropOff];
    const viableRoutes = truckRoutes.filter((truckRoute) => {
      const truckRouteDistance = calculateTotalDistance(truckRoute.address);
      const userRouteDistance = calculateTotalDistance(userRoute);
      const toleranceLowerBound = userRouteDistance * 0.9;
      const toleranceUpperBound = userRouteDistance * 1.1;
      const distanceCheck =
        truckRouteDistance >= toleranceLowerBound &&
        truckRouteDistance <= toleranceUpperBound;
      const directionalityCheck = isDirectionAligned(
        userRoute,
        truckRoute.address
      );
      const proximityCheck = calculateProximityScore(
        userRoute,
        truckRoute.address
      );
      const consistencyCheck = isRouteConsistent(userRoute, truckRoute.address);



      return (
        //distanceCheck &&
        directionalityCheck &&
        proximityCheck &&
        consistencyCheck
      );
    });
    if (viableRoutes.length > 0) {
      let flaggedRoutes = [];
      for (const Routes of viableRoutes) {
        Routes["canBeBooked"] = true;
        flaggedRoutes.push(Routes);
      }
      return flaggedRoutes;
    }
    return viableRoutes;
  }
  const isSpecialAdmin=()=>{
     
    return auth.info?.profile?.specialAdmin || giveIdThenIwillGiveYouData(auth.info?.profile?.carrierId)?.specialAdmin
  }

  return bookinglistdata.loading || usersdata.loading ? (
    <CircularLoading />
  ) : (
    <div>
       {isRedirected&&<IconButton onClick={() => props.history.push(`/${isRedirected}`)}><ArrowBackIcon/></IconButton>}
        {(auth.info.profile.usertype === "shipper" || auth.info.profile.brokerId ) &&
        sharedCariers &&
        CarriersData?.length > 0 && (
          <div
            style={{
              textAlign: "center",
              margin: "5px auto 20px ",
              width: "60%",
            }}
          >
            <FormControl style={{ width: "60%", minWidth: "300px" }}>
              <InputLabel id="demo-simple-select-label">
                Carriers That Shared Their Dashboard
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={ActiveCarrier}
                label="carriers"
                onChange={handleChange}
              >
                {CarriersData.map((e, index) => {
                  return (
                    <MenuItem key={index} value={e.value}>
                      {e.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        )}

      {(auth.info.profile.usertype === "shipper" &&
        sharedCariers &&
        CarriersData.length > 0) ||
         auth.info.profile.usertype !== "shipper" ? (
        <>

          {!isSpecialAdmin() && 
            <>
              <div style={{ width: "90%", margin: "0px auto" }}>
                <div
                  className={classes.containerInput}
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                    flexWrap: "wrap",
                    margin: "10px 0 10px 0 ",
                    padding: 0,
                  }}
                >
                  <div style={{ width: "160px" }}>
                    <Paper>
                      <GoogleMapsAutoComplete
                        placeholder={t("pickup_location")}
                        variant={"filled"}
                        value={pickupAddress}
                        onChange={(value) => {
                          setPickupAddress(value);
                        }}
                      />
                    </Paper>
                  </div>

                  <div style={{ width: "160px" }}>
                    <Paper>
                      <GoogleMapsAutoComplete
                        placeholder={t("drop_location")}
                        variant={"filled"}
                        value={dropAddress}
                        onChange={(value) => {
                          setDropAddress(value);
                        }}
                      />
                    </Paper>
                  </div>

                  <div style={{ width: "fit-content" }}>
                    {trailertypes && trailerType ? (
                      <FormControl style={{ width: "100%" }}>
                        <Select
                          id="car-type-native"
                          value={trailerType}
                          onChange={handleTrailerSelect}
                          style={{
                            textAlign: isRTL === "rtl" ? "right" : "left",
                            height: "58px",
                          }}
                          className={
                            trailerType === t("select_car")
                              ? classes.inputdimmed
                              : classes.input
                          }
                        >
                          <MenuItem
                            value={"Select Trailer Type"}
                            key={"Select Trailer Type"}
                            style={{
                              direction: isRTL === "rtl" ? "rtl" : "ltr",
                            }}
                          >
                            {t("car_type_blank_error")}
                          </MenuItem>
                          {trailertypes.map((trailer) => (
                            <MenuItem
                              key={trailer.name}
                              value={trailer.name}
                              style={{
                                direction: isRTL === "rtl" ? "rtl" : "ltr",
                              }}
                            >
                              <img
                                src={trailer.image}
                                className={
                                  isRTL === "rtl"
                                    ? classes.carphotoRtl
                                    : classes.carphoto
                                }
                                alt="car types"
                              />
                              {t(trailer.name)}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : null}
                  </div>

                  <div style={{ width: "fit-content" }}>
                    <TextField
                      id="datetime-local"
                      type="datetime-local"
                      variant="filled"
                      fullWidth
                      className={
                        isRTL === "rtl"
                          ? [classes.inputRtl, classes.commonInputStyle]
                          : classes.commonInputStyle
                      }
                      InputProps={{
                        className: classes.input,
                        style: {
                          textAlignLast: isRTL === "rtl" ? "end" : "start",
                          height: "58px",
                        },
                      }}
                      value={selectedDate || ""}
                      onChange={onDateChange}
                    />
                  </div>
                </div>
              </div>

              <div style={{ textAlign: "center", width: "100%" }}>
                <Button
                  color="primaryButton"
                  size="lg"
                  rel="noopener noreferrer"
                  className={classes.items}
                  onClick={clearFilterAndMatch}
                  style={{
                    height: 40,
                    marginLeft: "10px",
                    width: "150px",
                    minWidth: "200",
                    backgroundColor: pageBackgroundColor(),
                    color:"white"
                  }}
                >
                  <i
                    className="fas fa-times-circle"
                    style={
                      isRTL === "rtl"
                        ? { marginLeft: "10px" }
                        : { marginRight: "10px" }
                    }
                  />
                  {t("clear_button")}
                </Button>
              </div>

            </>
          }

          {/* {mylocation ? */}
          <div style={{ marginTop: "5px" }}>
            {/* <Typography variant="h4" style={{margin:"20px 20px 0 15px",textAlign:isRTL==='rtl'?'right':'left'}}>{'Real-Time Map'}</Typography> */}
            <Map
              mapcenter={centerOfMap}
              locations={MapLocations||[]}
              loadingElement={<div style={{ height: `65vh` }} />}
              containerElement={<div />}
              mapElement={<div style={{ height: isSpecialAdmin()?'80vh':`65vh` }} />}
              type="dashboard"
              pickupAddress={pickupAddress}
              dropAddress={dropAddress}
              selectedDate={selectedDate}
              trailerType={trailerType}
              boardType={"None"}
              history={props.history}
              ActiveCarrier={ActiveCarrier}
            />
          </div>
          {/* : */}
          {/* <Typography variant="h6" style={{ margin: "20px 0 0 15px", color: '#FF0000' }}>{t('allow_location')}</Typography>
              } */}
        </>
      ) : (
        <h2 style={{ textAlign: "center", color: pageBackgroundColor(), marginTop: "20%" }}>
          You have no dashboard to show up here
        </h2>
      )}
      
       <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={openAddDriverModal}
        onClose={()=>setOpenAddDriverModal(false)}
        className={classes.modal}
        container={() => rootRef.current}
      >
        <Grid
          container
          spacing={1}
          className={classes.paper}
          style={{ direction: isRTL === "rtl" ? "rtl" : "ltr", border: 0 }}
        >
          <Grid style={{textAlign: 'center', color: pageBackgroundColor(), display: 'flex', justifyContent: 'center',fontSize:"18px"}}>
              {auth.info?.profile?.usertype === 'shipper'||auth.info?.profile?.brokerId ?
                    <>
                      Carriers that you have added to your network have not approved you to see their fleet map
                    </>
              :
                 <>
                   Please go setup your driver’s current location and input potential capacity to share with your team and network
                  </>
              }
          </Grid>
          
        </Grid>
      </Modal> 
    </div>
  );
};

export default Dashboard;
