import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Select,
  MenuItem,
  Typography,
  TextField,
  FormControlLabel,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  InputAdornment,
} from "@mui/material";
import {Grid,Modal} from "@material-ui/core"
import GoogleMapsAutoComplete from "../components/GoogleMapsAutoComplete";
import { useSelector, useDispatch } from "react-redux";
import AlertDialog from "../components/AlertDialog";
import { makeStyles } from "@material-ui/core/styles";
import UsersCombo from "../components/UsersCombo";
import { FirebaseContext } from "common";
import Button from "components/CustomButtons/Button.js";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Box, CircularProgress, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import StartIcon from "@mui/icons-material/Start";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  modal: {
    display: "flex",
    padding: theme.spacing(1),
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    overflow: "auto",
  },
  paper: {
    width: 480,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    "media(max-width:450px)": {
      width: "90%",
    },
  },
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    alignContent: "center",
  },
  title: {
    color: "#000",
  },
  items: {
    margin: 0,
    width: "100%",
  },
  input: {
    fontSize: 18,
    color: "#000",
  },
  inputdimmed: {
    fontSize: 18,
    color: "#737373",
  },
  carphoto: {
    height: "18px",
    marginRight: "10px",
  },
  carphotoRtl: {
    height: "16px",
    marginLeft: "10px",
  },
  buttonStyle: {
    margin: 0,
    width: "100%",
    height: "100%",
    color: "black",
    fontWeight: 600,
  },
  inputRtl: {
    "& label": {
      right: 25,
      left: "auto",
    },
    "& legend": {
      textAlign: "right",
      marginRight: 18,
    },
  },
  rightRty: {
    "& legend": {
      marginRight: 30,
    },
  },
}));

export default function AddBookings(props) {
  const { api } = useContext(FirebaseContext);
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const {
    getEstimate,
    clearEstimate,
    addBooking,
    clearBooking,
    fetchRoutes,
    fetchRouteTypes,
    allCarriersDriverLocation,
  } = api;
  const dispatch = useDispatch();
  const classes = useStyles();
  const auth = useSelector((state) => state.auth);
  const locationdata = useSelector((state) => state.locationdata);

  const trailertypes = useSelector((state) => state.trailertypes.trailers);
  const estimatedata = useSelector((state) => state.estimatedata);
  const bookingdata = useSelector((state) => state.bookingdata);
  const userdata = useSelector((state) => state.usersdata);
  const settings = useSelector((state) => state.settingsdata.settings);
  const [trailerType, setTrailerType] = useState(t("select_car"));
  const [pickupAddress, setPickupAddress] = useState(null);
  const [dropAddress, setDropAddress] = useState(null);
  const [optionModalStatus, setOptionModalStatus] = useState(false);
  const [estimateModalStatus, setEstimateModalStatus] = useState(false);
  const [selectedTrailerDetails, setSelectedTrailerDetails] = useState(null);
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });
  const [driverCombo, setDriverCombo] = useState(null);
  const [customerCombo, setCustomerCombo] = useState(null);
  const [customerClientCombo, setCustomerClientCombo] = useState(null);

  const [estimateRequested, setEstimateRequested] = useState(false);
  const rootRef = useRef(null);
  const [tempRoute, setTempRoute] = useState();
  const [drivers, setDrivers] = useState([]);
  const [customers, setCustomers] = useState([]);
  const routeTypes = useSelector((state) => state.routetypes.routes);
  const [SharedCariers, SetSharedCarriers] = useState([]);
  const [ActiveSharedCarrier, SetActiveSharedCarrier] = useState(null);
  const routes = useSelector((state) => state.routes);
  const [availableRoutes, setAvailableRoutes] = useState([]);
  const [targetRoute, setTargetRoute] = useState(null);
  const bookinglistdata = useSelector((state) => state.bookinglistdata);
  const [targetCustomer, setTargetCustomer] = useState(null);

  const getAssignedBookings = (driverId) => {
    if (
      !bookinglistdata ||
      !bookinglistdata?.bookings ||
      bookinglistdata?.bookings?.length === 0
    )
      return null;

    const assignedBookingsList = bookinglistdata?.bookings?.filter((e) => {
      return (
        e.driverDetails?.id === driverId &&
        (e.status === "STARTED" ||
          e.status === "ACCEPTED" ||
          e.status === "NEW" ||
          e.status === "ARRIVED")
      );
    });

    return assignedBookingsList.length;
  };
  useEffect(() => {
    if (routes.routes && routes.routes.length > 0) {
      const activeRoute = routes.routes.filter((e) => e.isActive);
      if (activeRoute.length > 0) {
        const updatedRoute = activeRoute.map((e) => {
          return {
            ...e,
            uid: e.id,
            desc: `${e.identification} ${e.pickup?.description} --> ${e.drop?.description}`,
          };
        });
        setAvailableRoutes(updatedRoute);
      } else setAvailableRoutes([]);
    }
  }, [routes]);

  const [allDriversLocation, setAllDriversLocation] = useState([]);
  useEffect(() => {
    if (auth?.info?.profile?.usertype === "fleetadmin") {
      dispatch(allCarriersDriverLocation(auth?.info?.uid));
    } else if (auth?.info?.profile?.carrierId) {
      dispatch(allCarriersDriverLocation(auth?.info?.profile?.carrierId));
    }
  }, [dispatch, allCarriersDriverLocation, auth]);
  useEffect(() => {
    if (locationdata?.fetchDriverLocation) {
      setAllDriversLocation(locationdata?.fetchDriverLocation);
    } else {
      setAllDriversLocation([]);
    }
  }, [locationdata]);

  useEffect(() => {
    if (auth?.info?.profile?.usertype === "fleetadmin") {
      dispatch(fetchRoutes(auth.info.uid));
    } else dispatch(fetchRoutes(auth.info.profile?.carrierId));
  }, [dispatch, fetchRoutes, auth]);

  const [instructionData, setInstructionData] = useState({
    deliveryPerson: "",
    brokerName: "",
    assignedPersonName: "",
    loadWeight: null,
    paymentAmount: null,
    assignedPersonPhone: "",
    deliveryPersonPhone: "",
    pickUpInstructions: "",
    deliveryInstructions: "",
    optionIndex: 0,
    optionSelected: null,
    shipperOffer: "",
    pickupDate: moment(new Date()).format("YYYY-MM-DDTHH:mm"),
    dropDate: moment(new Date()).format("YYYY-MM-DDTHH:mm"),
    commodity: "",
    loadIdentification: "",
    merchantName: "",
    shipperName: "",
    merchantPhone: "",
    shipperPhone: "",
    description: "",
    depositTransactionRef: "",
    paymentTransactionRef: "",
  });

  function isSuperDispatcher() {
    return (
      auth.info.profile.usertype === "dispatcher" &&
      auth.info.profile.independent
    );
  }

  useEffect(() => {
    if (!ActiveSharedCarrier) {
      setDriverCombo(null);
    }
  }, [ActiveSharedCarrier]);

  const giveIdThenIwillGiveYouData = (id) => {
    const targetUser = userdata.users.filter((e) => {
      return e.id === id;
    });
    if (targetUser.length > 0) return targetUser[0];
    return null;
  };

  useEffect(() => {
    if (
      (isSuperDispatcher() || auth.info?.profile?.dispatcherId) &&
      userdata.users
    ) {
      let SharedCarriers = auth.info?.profile?.dispatcherId
        ? giveIdThenIwillGiveYouData(auth.info?.profile?.dispatcherId)
            ?.SharedCarriers
        : auth.info.profile.SharedCarriers;
      if (SharedCarriers && SharedCarriers.length > 0) {
        let carriers = [];
        SharedCarriers.map((e) => {
          if (e.approved && e.aprovedByCarrier) {
            const carrier = userdata.users.filter((element) => {
              return element.id === e.id;
            });
            if (carrier.length > 0) {
              carriers.push({
                ...carrier[0],
                desc:
                  carrier[0].firstName +
                  " " +
                  carrier[0].lastName +
                  " (" +
                  (settings.AllowCriticalEditsAdmin
                    ? carrier[0].mobile
                    : "Hidden") +
                  ") " +
                  (settings.AllowCriticalEditsAdmin
                    ? carrier[0].email
                    : "Hidden"),
              });
            }
          }
        });
        SetSharedCarriers(carriers);
      } else SetSharedCarriers([]);
    } else {
      SetSharedCarriers([]);
    }
  }, [auth.info, userdata.users]);

  useEffect(() => {
    dispatch(fetchRouteTypes());
  }, [dispatch, fetchRouteTypes]);

  const [deliveryPersonPhoneWithOutPlus, setDeliveryPersonPhoneWithOutPlus] =
    useState("");
  const handleChange = (e) => {
    if (e.target.name === "optionIndex") {
      const getSelectedRouteType = routeTypes.filter((element) => {
        return element.name === e.target.value;
      });

      if (getSelectedRouteType.length > 0) {
        setInstructionData({
          ...instructionData,
          optionIndex: e.target.value,
          optionSelected: getSelectedRouteType[0],
        });
      }
    } else {
      if (e.target.name === "deliveryPersonPhone") {
        let deliveryPersonPhoneValue = e.target.value.replace(/[^0-9]/g, "");
        deliveryPersonPhoneValue = "+" + deliveryPersonPhoneValue;
        setDeliveryPersonPhoneWithOutPlus(
          e.target.value.replace(/[^0-9]/g, "")
        );
        setInstructionData({
          ...instructionData,
          [e.target.name]: deliveryPersonPhoneValue,
        });
      } else {
        setInstructionData({
          ...instructionData,
          [e.target.name]: e.target.value,
        });
      }
    }
  };

  const handleTrailerSelect = (event) => {
    setTrailerType(event.target.value);
    let trailerDetails = null;
    for (let i = 0; i < trailertypes.length; i++) {
      if (trailertypes[i].name === event.target.value) {
        trailerDetails = trailertypes[i];
        let instObj = { ...instructionData };

        if (Array.isArray(trailertypes[i].options)) {
          instObj.optionSelected = trailertypes[i].options[0];
          instObj.optionIndex = 0;
        }
        setInstructionData(instObj);
      }
    }
    setSelectedTrailerDetails(trailerDetails);
  };

  const location = useLocation();
  const [recommandText, setRecommandText] = useState(false);
  const [isUserComesFromDriverTable, setIsUserComesFromDriverTable] = useState({
    status: false,
    count: 0,
  });

  useEffect(() => {
    if (location.search) {
      const queryParams = new URLSearchParams(location.search);
      const carrierId = queryParams.get("carrier");

      if (carrierId) {
        let carrierData = SharedCariers.filter((e) => {
          return e.id === carrierId;
        });

        if (carrierData.length > 0) {
          SetActiveSharedCarrier(carrierData[0]);
        }
      }
    }
  }, [SharedCariers]);

  useEffect(() => {
    if (location.search) {
      const queryParams = new URLSearchParams(location.search);
      const userId = queryParams.get("user");
      if (userId && isUserComesFromDriverTable.count === 0) {
        let driverData = drivers.filter((e) => {
          return e.id === userId;
        });
        if (driverData.length > 0) {
          setDriverCombo(driverData[0]);
          setRecommandText(true);
          setIsUserComesFromDriverTable({ status: true, count: 1 });
        }
      }
    } else {
      setIsUserComesFromDriverTable({ status: false, count: 0 });
    }
  }, [drivers]);

  const handleCloseRecommandText = () => {
    setRecommandText(false);
  };

  useEffect(() => {
    if (estimatedata.estimate && estimateRequested) {
      setEstimateRequested(false);
      setEstimateModalStatus(true);
    }
    if (userdata?.users) {
      const arrCustomers = userdata.users
        .filter((e) => {
          return (
            e.usertype === "customer" &&
            (e.carrierId === auth?.info?.uid  || e.carrierId === auth?.info?.profile.carrierId) &&
            e.approved &&
            e.emailVerified
          );
        })
        .map((e) => {
          return {
            uid: e.id,
            desc: e?.shopeName ? `${e?.shopeName} (Owner: ${e.firstName} ${e.lastName}) - Business Account` : `${e.firstName} ${e.lastName} - Non Business Account`,
            ...e,
          };
        });
      setCustomers(arrCustomers);
    }

    if (userdata.users) {
      let arrDrivers = [];
      for (let i = 0; i < userdata.users.length; i++) {
        let user = userdata.users[i];
        if (
          (!user.approved || !user.emailVerified) &&
          user.usertype === "driver"
        ) {
          continue;
        }
        const filteredLocation = allDriversLocation.filter((e) => {
          return e.driverId === userdata?.users[i]?.id;
        });

        if (
          filteredLocation.length === 0 ||
          user?.usertype !== "driver" ||
          user?.queue ||
          !user?.driverActiveStatus
        )
          continue;
        const assignedBookings = getAssignedBookings(userdata?.users[i]?.id);

        const driverLocation = filteredLocation[0]?.location;
        if (auth.info.profile.usertype === "admin") {
          arrDrivers.push({
            uid: user.id,
            location: driverLocation,
            assignedBookings: assignedBookings,
            trailerType: user.trailerType,
            desc:
              user.firstName +
              " " +
              user.lastName +
              " (" +
              driverLocation.add +
              ") " +
              (assignedBookings ? `${assignedBookings} Assigned shipment` : ""),
            ...user,
          });
        }
        if (
          (isSuperDispatcher() || auth.info?.profile?.dispatcherId) &&
          ActiveSharedCarrier
        ) {
          if (user.fleetadmin === ActiveSharedCarrier.id) {
            arrDrivers.push({
              uid: user.id,
              location: driverLocation,
              assignedBookings: assignedBookings,
              trailerType: user.trailerType,
              desc:
                user.firstName +
                " " +
                user.lastName +
                " (" +
                driverLocation.add +
                ") " +
                (assignedBookings
                  ? `${assignedBookings} Assigned shipment`
                  : ""),
              ...user,
            });
          }
        } else if (auth.info.profile.usertype === "fleetadmin") {
          if (user.fleetadmin === auth.info.uid) {
            arrDrivers.push({
              uid: user.id,
              location: driverLocation,
              assignedBookings: assignedBookings,
              trailerType: user.trailerType,
              desc:
                user.firstName +
                " " +
                user.lastName +
                " (" +
                driverLocation.add +
                ") " +
                (assignedBookings
                  ? `${assignedBookings} Assigned shipment`
                  : ""),
              ...user,
            });
          }
        } else if (
          (auth.info.profile.usertype === "dispatcher") &
          !(isSuperDispatcher() || auth.info?.profile?.dispatcherId)
        ) {
          if (user.fleetadmin === auth.info.profile.carrierId) {
            arrDrivers.push({
              uid: user.id,
              location: driverLocation,
              assignedBookings: assignedBookings,
              trailerType: user.trailerType,
              desc:
                user.firstName +
                " " +
                user.lastName +
                " (" +
                driverLocation.add +
                ") " +
                (assignedBookings
                  ? `${assignedBookings} Assigned shipment`
                  : ""),
              ...user,
            });
          }
        }
      }

      setDrivers(arrDrivers);
    }
  }, [
    bookinglistdata?.bookings,
    allDriversLocation,
    ActiveSharedCarrier,
    estimatedata.estimate,
    userdata.users,
    estimateRequested,
    settings.AllowCriticalEditsAdmin,
  ]);

  const handleGetOptions = (e) => {
    e.preventDefault();
    if (!driverCombo) {
      setCommonAlert({
        open: true,
        msg: "Please finish setting up your drivers and their info before assigning them loads",
      });
      return;
    }
    setEstimateRequested(true);
    if (
      driverCombo &&
      pickupAddress &&
      dropAddress &&
      (!isSpecialAdmin() ? selectedTrailerDetails : true)
    ) {
      const directionService = new window.google.maps.DirectionsService();
      directionService.route(
        {
          origin: new window.google.maps.LatLng(
            pickupAddress.coords.lat,
            pickupAddress.coords.lng
          ),
          destination: new window.google.maps.LatLng(
            dropAddress.coords.lat,
            dropAddress.coords.lng
          ),
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            const route = {
              distance_in_km: result.routes[0].legs[0].distance.value / 1000,
              time_in_secs: result.routes[0].legs[0].duration.value,
              polylinePoints: result.routes[0].overview_polyline,
            };
            setTempRoute(route);
            if (
              !isSpecialAdmin() &&
              Array.isArray(selectedTrailerDetails.options)
            ) {
              setOptionModalStatus(true);
            } else {
              let estimateRequest = {
                pickup: pickupAddress,
                drop: dropAddress,
                trailerDetails: selectedTrailerDetails,
                instructionData: instructionData,
                routeDetails: route,
              };
              dispatch(getEstimate(estimateRequest));
            }
          } else {
            setCommonAlert({ open: true, msg: t("place_to_coords_error") });
          }
        }
      );
    } else if (!pickupAddress || !dropAddress) {
      setCommonAlert({
        open: true,
        msg: "Pickup and drop-off location (Routes) are required",
      });
    } else {
      setCommonAlert({ open: true, msg: "Please fill all fields" });
    }
  };

  const handleGetEstimate = (e) => {
    e.preventDefault();
    setOptionModalStatus(false);
    if (!driverCombo) {
      setCommonAlert({
        open: true,
        msg: "Please finish setting up your drivers and their info before assigning them loads",
      });
    } else {
      let estimateRequest = {
        pickup: pickupAddress,
        drop: dropAddress,
        trailerDetails: selectedTrailerDetails,
        instructionData: instructionData,
        routeDetails: tempRoute,
      };
      dispatch(getEstimate(estimateRequest));
    }
  };

  const isSpecialAdmin = () => {
    return (
      auth.info?.profile?.specialAdmin ||
      giveIdThenIwillGiveYouData(auth.info?.profile?.carrierId)?.specialAdmin
    );
  };

  useEffect(() => {
    if (isSpecialAdmin() && targetRoute) {
      const theRoute = availableRoutes.filter((e) => {
        return e.id === targetRoute.id;
      });
      if (theRoute.length > 0) {
        setPickupAddress(theRoute[0].pickup);
        setDropAddress(theRoute[0].drop);
      }
    }
  }, [targetRoute, availableRoutes]);

  const isNotValidPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return "Phone Number is Required";
    if (phoneNumber.length !== 13)
      return "Phone numbe should be in such format +251912321234.";
    if (!phoneNumber.startsWith("+251"))
      return "Phone numbe should be in such format +251912321234.";

    return false;
  };

  const isValidTransactionReference = (reference) => {
    if (!reference) return false;
    if (reference.length < 12) return false;
    return true;
  };

  const [loading, setLoading] = useState(false);

  const [selectedCustomerType, setSelectedCustomerType] = useState('shipper');
  const [wantToMakePaymentNow, setWantToMakePaymentNow] = useState('no');


  const confirmBooking = (e) => {
    e.preventDefault();
    const regx1 = /([0-9\s-]{7,})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;

    if( isSpecialAdmin() ){
      if ((!customerCombo||(customerCombo&&selectedCustomerType==='shipper'&&!customerClientCombo))  && (!instructionData.merchantName || isNotValidPhoneNumber("+251" + instructionData?.merchantPhone))) {
          setCommonAlert({
            open: true,
            msg:  isNotValidPhoneNumber("+251" + instructionData?.merchantPhone)?"Invalid Receiver Phone Number":`Receiver name is Required`,
          });
      } 
       else if ((!customerCombo||(customerCombo&&selectedCustomerType==='receiver'&&!customerClientCombo))  && (isNotValidPhoneNumber("+251" + instructionData?.shipprPhone)|| !instructionData.shipperName)) {
          setCommonAlert({
            open: true,
            msg: isNotValidPhoneNumber("+251" + instructionData?.shipprPhone)?"Invalid Shipper Phone Number":`Shipper name is Required`,
          });
      } 
      else if ((!isValidTransactionReference(instructionData.depositTransactionRef) && customerCombo && wantToMakePaymentNow==='no' )|| 
               ((!customerCombo||(customerCombo&&wantToMakePaymentNow==='yes'))&&!isValidTransactionReference(instructionData.paymentTransactionRef))) {
        setCommonAlert({
          open: true,
          msg: `Please use valid transactiion references`,
        });
      } 
      else if ((!customerCombo||(customerCombo&&wantToMakePaymentNow === 'yes'))&&!instructionData.loadWeight) {
        setCommonAlert({ open: true, msg: `Weight Is Required` });
      } 
      else if ((!customerCombo||(customerCombo&&wantToMakePaymentNow === 'yes'))&&!instructionData.paymentAmount) {
        setCommonAlert({ open: true, msg: `Payment amount Is Required` });
      } 
      else if (
        new Date(instructionData.dropDate).getTime() < new Date().getTime() ||
        new Date(instructionData.pickupDate).getTime() < new Date().getTime()
      ) {
        setCommonAlert({
          open: true,
          msg: "The Drop Date and Pickup Date cannot be Past Dates.",
        });
      } 
      else if (
        new Date(instructionData.dropDate).getTime() <=
        new Date(instructionData.pickupDate).getTime()
      ) {
        setCommonAlert({
          open: true,
          msg: "The Drop Date cannot be earlier than the Pickup Date.",
        });
      } else {
        setEstimateModalStatus(false);
  
        const bookingObject = {
          approved: true,
          pickup: pickupAddress,
          drop: dropAddress,
          assigndTo: auth.info.uid,
          driverDetails: { id: driverCombo.id },
          fleetadmin: driverCombo.fleetadmin,
          estimate: estimatedata.estimate,
          bookLater: true,
          booking_type_admin: true,
          customBooking: true,
          manual: true,
          customer:customerCombo?.id,
          customerClientCombo:customerClientCombo?.id,
          wantToMakePaymentNow:wantToMakePaymentNow,
          selectedCustomerType:selectedCustomerType,
          paymentAmount: instructionData?.paymentAmount,
          loadWeight: instructionData?.loadWeight,
          pickupDate: new Date(instructionData.pickupDate).getTime(),
          dropDate: new Date(instructionData.dropDate).getTime(),
          merchantName: instructionData?.merchantName,
          shipperName: instructionData?.shipperName,
          merchantPhone:instructionData?.merchantPhone? "+251" + instructionData?.merchantPhone:null,
          shipperPhone: instructionData?.shipperPhone?"+251" + instructionData?.shipperPhone:null,
  
          description: instructionData?.description,
          depositTransactionRef: instructionData?.depositTransactionRef,
          paymentTransactionRef: instructionData?.paymentTransactionRef,
  
          route: {
            id: targetRoute.id,
            identification: targetRoute.identification,
          },
          adminBankAccount:
            auth?.info?.profile?.usertype === "fleetadmin"
              ? auth?.info?.profile?.adminBankAccount
              : giveIdThenIwillGiveYouData(auth?.info?.profile?.carrierId)
                  ?.adminBankAccount,
        };

          setLoading(true);
          dispatch(api.addCustomBookingWithTransactionReference(bookingObject))
            .then((res) => {
              setLoading(false);
              setTargetRoute(null)
              // setCommonAlert({
              //   open: true,
              //   msg: "",
              // });
              setInstructionData({
                deliveryPerson: "",
                brokerName: "",
                assignedPersonName: "",
                loadWeight: null,
                paymentAmount: null,
                assignedPersonPhone: "",
                deliveryPersonPhone: "",
                pickUpInstructions: "",
                deliveryInstructions: "",
                optionIndex: 0,
                optionSelected: null,
                shipperOffer: "",
                commodity: "",
                loadIdentification: "",
                merchantName: "",
                merchantPhone: "",
                shipperName: "",
                shipperPhone: "",
                description: "",
                pickupDate: moment(new Date()).format("YYYY-MM-DDTHH:mm"),
                dropDate: moment(new Date()).format("YYYY-MM-DDTHH:mm"),
                paymentTransactionRef: "",
                depositTransactionRef: "",
              });
              setDriverCombo(null);
              setCustomerCombo(null);
              setCustomerClientCombo(null);
              setWantToMakePaymentNow("no")
              SetActiveSharedCarrier(null);
            })
            .catch((error) => {
              setInstructionData({
                ...instructionData,
                loadWeight: null,
                paymentAmount: null,
                merchantName: "",
                merchantPhone: "",
                shipperName: "",
                shipperPhone: "",
                paymentTransactionRef: "",
                depositTransactionRef: "",
              });
              // console.log(error);
              setCommonAlert({
                open: true,
                msg: error?.error || "Error occured",
              });
              setLoading(false);
            });
        
        // else {
        //   dispatch(addBooking(bookingObject));
        //   setInstructionData({
        //     deliveryPerson: "",
        //     brokerName: "",
        //     assignedPersonName: "",
        //     loadWeight: null,
        //     paymentAmount: null,
        //     assignedPersonPhone: "",
        //     deliveryPersonPhone: "",
        //     pickUpInstructions: "",
        //     deliveryInstructions: "",
        //     optionIndex: 0,
        //     optionSelected: null,
        //     shipperOffer: "",
        //     commodity: "",
        //     loadIdentification: "",
        //     merchantName: "",
        //     merchantPhone: "",
        //     shipperName: "",
        //     shipperPhone: "",
        //     description: "",
        //     pickupDate: moment(new Date()).format("YYYY-MM-DDTHH:mm"),
        //     dropDate: moment(new Date()).format("YYYY-MM-DDTHH:mm"),
        //     paymentTransactionRef: "",
        //     depositTransactionRef: "",
        //   });
        //   setDriverCombo(null);
        //   SetActiveSharedCarrier(null);
        // }
      }
    }
    else{
        if (isNotValidPhoneNumber("+251" + instructionData?.merchantPhone)) {
          setCommonAlert({
            open: true,
            msg: isNotValidPhoneNumber("+251" + instructionData?.merchantPhone),
          });
        }  
        else if (isNotValidPhoneNumber("+251" + instructionData?.shipperPhone)) {
          setCommonAlert({
            open: true,
            msg: isNotValidPhoneNumber("+251" + instructionData?.shipperPhone),
          });
        } 
        else if (!instructionData.loadWeight) {
          setCommonAlert({ open: true, msg: `Weight Is Required` });
        } 
        else if (
          new Date(instructionData.dropDate).getTime() < new Date().getTime() ||
          new Date(instructionData.pickupDate).getTime() < new Date().getTime()
        ) {
          setCommonAlert({
            open: true,
            msg: "The Drop Date and Pickup Date cannot be Past Dates.",
          });
        } 
        else if (
          new Date(instructionData.dropDate).getTime() <=
          new Date(instructionData.pickupDate).getTime()
        ) {
          setCommonAlert({
            open: true,
            msg: "The Drop Date cannot be earlier than the Pickup Date.",
          });
        }
        else if (
          /\S/.test(instructionData.deliveryPerson) &&
          regx1.test(instructionData.deliveryPersonPhone) &&
          instructionData.deliveryPersonPhone &&
          instructionData.deliveryPersonPhone.length > 6
        ) {
          setEstimateModalStatus(false);
    
          let bookingObject = {
            approved: true,
            pickup: pickupAddress,
            drop: dropAddress,
            trailerDetails: selectedTrailerDetails.name,
            driverDetails: { ...driverCombo },
            assigndTo: auth.info.uid,
            fleetadmin: driverCombo.fleetadmin,
            estimate: estimatedata.estimate,
            instructionData: {
              ...instructionData,
              assignedPersonPhone: "+" + instructionData.assignedPersonPhone,
            },
            pickupDate: new Date(instructionData.pickupDate).getTime(),
            dropDate: new Date(instructionData.dropDate).getTime(),
            bookLater: true,
            settings: settings,
            booking_type_admin: true,
            manualBooking: true,
          };

          dispatch(addBooking(bookingObject));
    
          setInstructionData({
            deliveryPerson: "",
            brokerName: "",
            assignedPersonName: "",
            loadWeight: null,
            paymentAmount: null,
            assignedPersonPhone: "",
            deliveryPersonPhone: "",
            pickUpInstructions: "",
            deliveryInstructions: "",
            optionIndex: 0,
            optionSelected: null,
            shipperOffer: "",
            pickupDate: moment(new Date()).format("YYYY-MM-DDTHH:mm"),
            dropDate: moment(new Date()).format("YYYY-MM-DDTHH:mm"),
            commodity: "",
            loadIdentification: "",
          });
          setDriverCombo(null);
          SetActiveSharedCarrier(null);
        } else {
          setCommonAlert({ open: true, msg: t("deliveryDetailMissing") });
        }
    }
  };

  const handleOptionModalClose = (e) => {
    e.preventDefault();
    setOptionModalStatus(false);
  };

  const handleEstimateModalClose = (e) => {
    e.preventDefault();
    setEstimateModalStatus(false);
    dispatch(clearEstimate());
    setEstimateRequested(false);
    setCustomerClientCombo(null)
    setInstructionData({
            deliveryPerson: "",
            brokerName: "",
            assignedPersonName: "",
            loadWeight: null,
            paymentAmount: null,
            assignedPersonPhone: "",
            deliveryPersonPhone: "",
            pickUpInstructions: "",
            deliveryInstructions: "",
            optionIndex: 0,
            optionSelected: null,
            shipperOffer: "",
            commodity: "",
            loadIdentification: "",
            merchantName: "",
            merchantPhone: "",
            shipperName: "",
            shipprPhone: "",
            description: "",
            pickupDate: moment(new Date()).format("YYYY-MM-DDTHH:mm"),
            dropDate: moment(new Date()).format("YYYY-MM-DDTHH:mm"),
            paymentTransactionRef: "",
            depositTransactionRef: "",
          });
  };

  const handleEstimateErrorClose = (e) => {
    e.preventDefault();
    dispatch(clearEstimate());
    setEstimateRequested(false);
  };

  const [noDriverFound, setNoDriverFound] = useState(false);
  useEffect(() => {
    if (userdata.users && drivers.length === 0) {
      setNoDriverFound(true);
    } else setNoDriverFound(false);
  }, [drivers, userdata]);
  const handleBookingAlertClose = (e) => {
    e.preventDefault();
    dispatch(clearBooking());
    dispatch(clearEstimate());
    clearForm();
    setNoDriverFound(false);
  };

  const clearForm = () => {
    setPickupAddress(null);
    setDropAddress(null);
    setSelectedTrailerDetails(null);
    setTrailerType(t("select_car"));
    setEstimateRequested(false);
  };

  const handleBookingErrorClose = (e) => {
    e.preventDefault();
    dispatch(clearBooking());
    setEstimateRequested(false);
  };

  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: "" });
  };

  const pageBackgroundColor = () => {
    if (auth?.info?.profile?.dashboardColor)
      return auth?.info?.profile?.dashboardColor;
    if (
      giveIdThenIwillGiveYouData(auth?.info?.profile?.carrierId)?.dashboardColor
    )
      return giveIdThenIwillGiveYouData(auth?.info?.profile?.carrierId)
        ?.dashboardColor;
    return "#01aac1";
  };

  const [customerClients,setCustomerClients]  = useState(null)
  useEffect(()=>{
      if(customers&&customers.length>0&&customerCombo){
          const filteredCustomer =  customers.filter(e=>{
             return e.id !== customerCombo.id && e.shopeName
          })
          setCustomerClients(filteredCustomer)
      }
      else{
        setCustomerClients([])
      }

  },[customers,customerCombo])
  return (
    <div className={classes.container} ref={rootRef}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Box sx={{ width: "60%" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {isUserComesFromDriverTable.status && (
                <IconButton onClick={() => props.history.push("/drivers")}>
                  <ArrowBackIcon />
                </IconButton>
              )}
              <Typography
                component="h1"
                variant="h5"
                className={classes.title}
                style={{ textAlign: "center", marginBottom: "13px" }}
              >
                {isUserComesFromDriverTable.status
                  ? "Attach Booking To Driver"
                  : "Create Manual Booking"}
              </Typography>
            </Grid>

            {(isSuperDispatcher() || auth.info?.profile?.dispatcherId) && (
              <Grid item xs={12} style={{ width: "100%" }}>
                {SharedCariers && SharedCariers.length > 0 ? (
                  <UsersCombo
                    className={classes.items}
                    placeholder={"Choose Carrier"}
                    users={SharedCariers}
                    value={ActiveSharedCarrier}
                    onChange={(event, newValue) => {
                      SetActiveSharedCarrier(newValue);
                    }}
                  />
                ) : null}
              </Grid>
            )}

            <Grid item xs={12}>
              {drivers && drivers.length > 0 ? (
                <UsersCombo
                  className={classes.items}
                  placeholder={"Select Drivers"}
                  users={drivers}
                  value={driverCombo}
                  onChange={(event, newValue) => {
                    setDriverCombo(newValue);
                  }}
                />
              ) : (
                <>
                  {routes?.loading || userdata?.loading ? (
                    <div style={{ color: "black", textAlign: "center" }}>
                      {" "}
                      <CircularProgress />
                    </div>
                  ) : (
                    <Typography style={{ color: "black", textAlign: "center" }}>
                      No Drivers are found
                    </Typography>
                  )}
                </>
              )}
            </Grid>
            
            {isSpecialAdmin() && (
              <Grid item xs={12}>
                {availableRoutes && availableRoutes.length > 0 ? (
                  <UsersCombo
                    className={classes.items}
                    placeholder={"Select Routes"}
                    users={availableRoutes}
                    value={targetRoute}
                    onChange={(event, newValue) => {
                      setTargetRoute(newValue);
                    }}
                  />
                ) : (
                  <>
                    {routes?.loading ? (
                      <div style={{ color: "black", textAlign: "center" }}>
                        {" "}
                        <CircularProgress />
                      </div>
                    ) : (
                      <Typography
                        style={{ color: "black", textAlign: "center" }}
                      >
                        No Routes are found
                      </Typography>
                    )}
                  </>
                )}
              </Grid>
            )}


            {isSpecialAdmin() && !routes?.loading && (
              <>
                <Grid item xs={12}>
                  {customers && customers.length > 0 ? (
                    <UsersCombo
                      className={classes.items}
                      placeholder={"Search and Select Customers (optional)"}
                      users={customers}
                      value={customerCombo}
                      onChange={(event, newValue) => {
                        setCustomerCombo(newValue);
                      }}
                    />
                  ) : (
                    <>
                    {routes?.loading || userdata?.loading ? (
                      null
                    ) : null}
                  </>
                  )}
                </Grid>

                {customerCombo&&<Grid item xs={12}>
                  <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">Today this customer is: </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="female"
                      name="radio-buttons-group"
                      row
                      value={selectedCustomerType}
                      onChange={(event)=>{
                        setSelectedCustomerType(event.target.value);
                      }}
                    >
                      <FormControlLabel value="shipper" control={<Radio />} label="Shipping" />
                      <FormControlLabel value="receiver" control={<Radio />} label="Receiving" />
                    </RadioGroup>
                  </FormControl>
                </Grid>}
              
              </>
            )}

            {!isSpecialAdmin() && (
              <>
                <Grid item xs={12}>
                  <GoogleMapsAutoComplete
                    variant={"outlined"}
                    placeholder={t("pickup_location")}
                    value={pickupAddress}
                    className={classes.items}
                    disabled={isSpecialAdmin()}
                    onChange={(value) => {
                      setPickupAddress(value);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <GoogleMapsAutoComplete
                    placeholder={t("drop_location")}
                    variant={"outlined"}
                    value={dropAddress}
                    disabled={isSpecialAdmin()}
                    className={classes.items}
                    onChange={(value) => {
                      setDropAddress(value);
                    }}
                  />
                </Grid>
              </>
            )}

            {!isSpecialAdmin() && (
              <Grid item xs={12} sm={6}>
                {trailertypes ? (
                  <Select
                    id="car-type-native"
                    value={trailerType}
                    onChange={handleTrailerSelect}
                    variant="outlined"
                    fullWidth
                    style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
                    className={
                      trailerType === t("select_car")
                        ? classes.inputdimmed
                        : classes.input
                    }
                  >
                    <MenuItem
                      value={t("select_car")}
                      key={t("select_car")}
                      style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
                    >
                      {t("select_car")}
                    </MenuItem>
                    {trailertypes.map((trailer) => (
                      <MenuItem
                        key={trailer.name}
                        value={trailer.name}
                        style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
                      >
                        <img
                          src={trailer.image}
                          className={
                            isRTL === "rtl"
                              ? classes.carphotoRtl
                              : classes.carphoto
                          }
                          alt="car types"
                        />
                        {trailer.name}
                      </MenuItem>
                    ))}
                  </Select>
                ) : null}
              </Grid>
            )}
          </Grid>
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <Button
              size="large"
              onClick={handleGetOptions}
              variant="contained"
              color="secondaryButton"
              className={classes.buttonStyle}
              style={{
                backgroundColor: pageBackgroundColor(),
                color: "white",
                width: "100%",
              }}
            >
              <StartIcon /> Next
            </Button>
          </div>
        </Box>
      </Box>

      {routeTypes && (
        <Modal
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          open={optionModalStatus}
          onClose={handleOptionModalClose}
          className={classes.modal}
          container={() => rootRef.current}
        >
          <Grid container spacing={2} className={classes.paper}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
            >
              {routeTypes && routeTypes.length > 0 ? (
                <FormControl component="fieldset">
                  <FormLabel component="legend">{t("options")}</FormLabel>
                  <RadioGroup
                    name="optionIndex"
                    value={instructionData.optionIndex}
                    onChange={handleChange}
                  >
                    {routeTypes.map((element, index) => (
                      <FormControlLabel
                        key={index}
                        value={element.name}
                        control={<Radio />}
                        label={element.name}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              ) : null}
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
            >
              <Button
                onClick={handleOptionModalClose}
                variant="contained"
                color="primary"
              >
                {t("cancel")}
              </Button>
              <Button
                onClick={handleGetEstimate}
                variant="contained"
                color="primary"
                style={
                  isRTL === "rtl" ? { marginRight: 10 } : { marginLeft: 10 }
                }
              >
                {t("get_estimate")}
              </Button>
            </Grid>
          </Grid>
        </Modal>
      )}

      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={estimateModalStatus || loading}
        onClose={handleEstimateModalClose}
        className={classes.modal}
        container={() => rootRef.current}
      >
        <Grid
          container
          spacing={1}
          className={classes.paper}
          style={{
            direction: isRTL === "rtl" ? "rtl" : "ltr",
            overflow: "auto",
            maxHeight: "80vh",
            top: 10,
          }}
        >
          <Typography
            component="h2"
            variant="h5"
            style={{ marginTop: 15, color: "#000" }}
          >
            {customerCombo?`Booking Information for ${selectedCustomerType && selectedCustomerType == "shipper" ? "Shipper: " : "Reciever: "} ${customerCombo?.shopeName ? customerCombo?.shopeName : customerCombo.firstName + ' ' + customerCombo.lastName}`:"Booking Information"}
           
          </Typography>
          {isSpecialAdmin() ? (
            <>
            {customerCombo&&<Grid item xs={12}>
                  <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">Do you want to make full payment now ?</FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="female"
                      name="radio-buttons-group"
                      row
                      value={wantToMakePaymentNow}
                      onChange={(event)=>{
                        setWantToMakePaymentNow(event.target.value);
                      }}
                    >
                      <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                      <FormControlLabel value="no" control={<Radio />} label="No" />
                    </RadioGroup>
                  </FormControl>
                </Grid>}

              {!routes?.loading&&customerCombo&&!(instructionData.merchantName ||instructionData.merchantPhone||instructionData.shipperName||instructionData.shipprPhone)&& (
                <>
                  <Grid item xs={12}>
                    {customers && customers.length > 0 ? (
                      <UsersCombo
                        className={classes.items}
                        placeholder={`Select ${selectedCustomerType==='receiver'?"Shipper":"Receiver"}`}
                        users={customerClients}
                        value={customerClientCombo}
                        onChange={(event, newValue) => {
                          setCustomerClientCombo(newValue);
                        }}
                      />
                    ) : (
                      <>
                      {routes?.loading || userdata?.loading ? (
                        null
                      ) : null}
                    </>
                    )}
                  </Grid>
                </>
              )}

              {(!customerCombo||(customerCombo&&selectedCustomerType==='shipper'&&!customerClientCombo))&&<Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="merchantName"
                  label={"Receiver Name"}
                  name="merchantName"
                  autoComplete="merchantName"
                  onChange={handleChange}
                  value={instructionData.merchantName}
                  className={isRTL === "rtl" ? classes.inputRtl : null}
                  style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
                />
              </Grid>}

              {(!customerCombo||(customerCombo&&selectedCustomerType==='shipper'&&!customerClientCombo))&&<Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  type="number"
                  fullWidth
                  id="merchantPhone"
                  label={"Receiver Phone"}
                  name="merchantPhone"
                  autoComplete="merchantPhone"
                  onChange={handleChange}
                  value={instructionData.merchantPhone}
                  autoFocus
                  className={isRTL === "rtl" ? classes.inputRtl : null}
                  style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+251</InputAdornment>
                    ),
                  }}
                />
              </Grid>}

              {(!customerCombo||(customerCombo&&selectedCustomerType==='receiver'&&!customerClientCombo))&&<Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="shipperName"
                  label={"Shipper Name"}
                  name="shipperName"
                  autoComplete="shipperName"
                  onChange={handleChange}
                  value={instructionData.shipperName}
                  className={isRTL === "rtl" ? classes.inputRtl : null}
                  style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
                />
              </Grid>}

              {(!customerCombo||(customerCombo&&selectedCustomerType==='receiver'&&!customerClientCombo))&&<Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  type="number"
                  fullWidth
                  id="shipperPhone"
                  label={"Shipper Phone"}
                  name="shipperPhone"
                  autoComplete="shipperPhone"
                  onChange={handleChange}
                  value={instructionData.shipperPhone}
                  autoFocus
                  className={isRTL === "rtl" ? classes.inputRtl : null}
                  style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+251</InputAdornment>
                    ),
                  }}
                />
              </Grid>}

             {(!customerCombo || (customerCombo&&wantToMakePaymentNow=='yes')) && <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="loadWeight"
                  label={"Weight"}
                  name="loadWeight"
                  autoComplete="loadWeight"
                  type="number"
                  onChange={handleChange}
                  value={instructionData.loadWeight}
                  autoFocus
                  className={isRTL === "rtl" ? classes.inputRtl : null}
                  style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <span>Kg</span>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>}

            {(!customerCombo || (customerCombo&&wantToMakePaymentNow=='yes'))&&<Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="paymentAmount"
                  label={"Payment Amount"}
                  name="paymentAmount"
                  autoComplete="paymentAmount"
                  type="number"
                  onChange={handleChange}
                  value={instructionData.paymentAmount}
                  autoFocus
                  className={isRTL === "rtl" ? classes.inputRtl : null}
                  style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <span>Birr</span>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>}

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="description"
                  label={"Description"}
                  name="description"
                  autoComplete="description"
                  onChange={handleChange}
                  value={instructionData.description}
                  className={isRTL === "rtl" ? classes.inputRtl : null}
                  style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  type="datetime-local"
                  margin="normal"
                  fullWidth
                  id="pickupDate"
                  label={"Pick Up Date"}
                  name="pickupDate"
                  autoComplete="pickupDate"
                  onChange={handleChange}
                  value={instructionData.pickupDate}
                  className={isRTL === "rtl" ? classes.inputRtl : null}
                  style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  type="datetime-local"
                  margin="normal"
                  fullWidth
                  id="dropDate"
                  label={"Drop Date"}
                  name="dropDate"
                  autoComplete="dropDate"
                  onChange={handleChange}
                  value={instructionData.dropDate}
                  className={isRTL === "rtl" ? classes.inputRtl : null}
                  style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
                />
              </Grid>

              {customerCombo&&wantToMakePaymentNow==='no' &&<Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="depositTransactionRef"
                  label={"Deposit Transaction Ref"}
                  name="depositTransactionRef"
                  autoComplete="depositTransactionRef"
                  onChange={handleChange}
                  value={instructionData.depositTransactionRef}
                  autoFocus
                  className={isRTL === "rtl" ? classes.inputRtl : null}
                  style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
                />
              </Grid>}

              {(!customerCombo||(customerCombo&&wantToMakePaymentNow==='yes'))&&
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="paymentTransactionRef"
                  label={!customerCombo||wantToMakePaymentNow==='yes'?"Total Payment Transaction Ref":"Full Payment Transaction Ref"}
                  name="paymentTransactionRef"
                  autoComplete="paymentTransactionRef"
                  onChange={handleChange}
                  value={instructionData.paymentTransactionRef}
                  autoFocus
                  className={isRTL === "rtl" ? classes.inputRtl : null}
                  style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
                />
              </Grid>}

            </>
          ) : (
            <>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  type={"date"}
                  margin="normal"
                  fullWidth
                  id="pickupDate"
                  label={"Pick Up Date"}
                  name="pickupDate"
                  autoComplete="pickupDate"
                  onChange={handleChange}
                  value={instructionData.pickupDate}
                  className={isRTL === "rtl" ? classes.inputRtl : null}
                  style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  type={"date"}
                  margin="normal"
                  fullWidth
                  id="dropDate"
                  label={"Drop Date"}
                  name="dropDate"
                  autoComplete="dropDate"
                  onChange={handleChange}
                  value={instructionData.dropDate}
                  className={isRTL === "rtl" ? classes.inputRtl : null}
                  style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="loadWeight"
                  label={"Weight"}
                  name="loadWeight"
                  autoComplete="loadWeight"
                  type="number"
                  onChange={handleChange}
                  value={instructionData.loadWeight}
                  autoFocus
                  className={isRTL === "rtl" ? classes.inputRtl : null}
                  style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <span>Kg</span>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="brokerName"
                  label={"Brokerage or Freight Forwarding Company"}
                  name="brokerName"
                  autoComplete="brokerName"
                  onChange={handleChange}
                  value={instructionData.brokerName}
                  autoFocus
                  className={isRTL === "rtl" ? classes.inputRtl : null}
                  style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="assignedPersonName"
                  label={"Assigned Contact Name"}
                  name="assignedPersonName"
                  autoComplete="assignedPersonName"
                  onChange={handleChange}
                  value={instructionData.assignedPersonName}
                  autoFocus
                  className={isRTL === "rtl" ? classes.inputRtl : null}
                  style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  type="number"
                  fullWidth
                  id="assignedPersonPhone"
                  label={"Assigned Contact Phone Number"}
                  name="assignedPersonPhone"
                  autoComplete="assignedPersonPhone"
                  onChange={handleChange}
                  value={instructionData.assignedPersonPhone}
                  autoFocus
                  className={isRTL === "rtl" ? classes.inputRtl : null}
                  style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+</InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="deliveryPerson"
                  label={t("deliveryPerson")}
                  name="deliveryPerson"
                  autoComplete="deliveryPerson"
                  onChange={handleChange}
                  value={instructionData.deliveryPerson}
                  autoFocus
                  className={isRTL === "rtl" ? classes.inputRtl : null}
                  style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  type="number"
                  id="deliveryPersonPhone"
                  label={t("deliveryPersonPhone")}
                  name="deliveryPersonPhone"
                  autoComplete="deliveryPersonPhone"
                  onChange={handleChange}
                  value={deliveryPersonPhoneWithOutPlus}
                  className={
                    isRTL === "rtl"
                      ? [classes.inputRtl, classes.rightRty]
                      : null
                  }
                  style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+</InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="pickUpInstructions"
                  label={t("pickUpInstructions")}
                  name="pickUpInstructions"
                  autoComplete="pickUpInstructions"
                  onChange={handleChange}
                  value={instructionData.pickUpInstructions}
                  className={isRTL === "rtl" ? classes.inputRtl : null}
                  style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="deliveryInstructions"
                  label={t("deliveryInstructions")}
                  name="deliveryInstructions"
                  autoComplete="deliveryInstructions"
                  onChange={handleChange}
                  value={instructionData.deliveryInstructions}
                  className={isRTL === "rtl" ? classes.inputRtl : null}
                  style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="commodity"
                  label={"Commodity"}
                  name="commodity"
                  autoComplete="commodity"
                  onChange={handleChange}
                  value={instructionData.commodity}
                  className={isRTL === "rtl" ? classes.inputRtl : null}
                  style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="loadIdentification"
                  label={"Load Identification"}
                  name="loadIdentification"
                  autoComplete="loadIdentification"
                  onChange={handleChange}
                  value={instructionData.loadIdentification}
                  className={isRTL === "rtl" ? classes.inputRtl : null}
                  style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
                />
              </Grid>
            </>
          )}

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ textAlign: "center" }}
          >
            {loading ? (
              <CircularProgress />
            ) : (
              <>
                <Button
                  onClick={handleEstimateModalClose}
                  variant="contained"
                  color="primary"
                  style={{
                    backgroundColor: pageBackgroundColor(),
                    color: "white",
                  }}
                >
                  {t("cancel_button")}
                </Button>
                <Button
                  onClick={confirmBooking}
                  variant="contained"
                  color="primary"
                  style={
                    isRTL === "rtl"
                      ? {
                          marginRight: 10,
                          backgroundColor: pageBackgroundColor(),
                          color: "white",
                        }
                      : {
                          marginLeft: 10,
                          backgroundColor: pageBackgroundColor(),
                          color: "white",
                        }
                  }
                >
                  {t("submit_capital")}
                </Button>
              </>
            )}
          </Grid>
        </Grid>
      </Modal>

      <AlertDialog
        open={bookingdata.booking ? true : false}
        onClose={handleBookingAlertClose}
      >
        {bookingdata.booking
          ? "Booking successful. Booking reference: " +
            bookingdata?.booking?.mainData?.reference
          : null}
      </AlertDialog>
      <AlertDialog
        open={noDriverFound && !routes?.loading}
        onClose={handleBookingAlertClose}
      >
        No Free Driver Found On Duty
      </AlertDialog>

      {/* <AlertDialog open={recommandText} onClose={handleCloseRecommandText}> 
          The 'Load in progress' status of the driver will be set to 'True' (assigned) only if the booking date is within 5 hours.
       </AlertDialog> */}

      <AlertDialog
        open={bookingdata.error.flag}
        onClose={handleBookingErrorClose}
      >
        {bookingdata.error.msg}
      </AlertDialog>
      <AlertDialog
        open={estimatedata.error.flag}
        onClose={handleEstimateErrorClose}
      >
        {estimatedata.error.msg}
      </AlertDialog>
      <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>
        {commonAlert.msg}
      </AlertDialog>
    </div>
  );
}
