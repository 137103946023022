import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Typography,
  MenuList,
  MenuItem,
  ListItemIcon,
  Modal,
  Grid,
  Box,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import MapIcon from '@mui/icons-material/Map';
import CarIcon from '@material-ui/icons/DirectionsCar';
import ExitIcon from '@material-ui/icons/ExitToApp';
import PeopleIcon from '@material-ui/icons/People';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import NotifyIcon from '@material-ui/icons/NotificationsActive';
import { FirebaseContext } from 'common';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Collapse from '@material-ui/core/Collapse';
import { useTranslation } from "react-i18next";
import SettingsIcon from '@material-ui/icons/Settings';
import LanguageIcon from '@material-ui/icons/Language';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import CancelScheduleSendIcon from '@material-ui/icons/CancelScheduleSend';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ContactPhoneIcon from '@material-ui/icons/AddBoxOutlined';
import HistoryOutlined from '@material-ui/icons/CheckCircle';
import DataThresholdingIcon from '@mui/icons-material/DataThresholding';
import AirlineSeatReclineNormalIcon from '@material-ui/icons/AirlineSeatReclineNormal';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import TurnedInIcon from '@mui/icons-material/TurnedIn';
import RouteIcon from '@mui/icons-material/Route';
import DepartureBoardIcon from '@mui/icons-material/DepartureBoard';
import BusinessIcon from '@mui/icons-material/Business';
import LocalShipping from '@material-ui/icons/LocalShipping';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import TableChartIcon from '@mui/icons-material/TableChart';
import HomeIcon from '@mui/icons-material/Home';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';


function AppMenu({handleDrawerToggle}) {
  const { api } = useContext(FirebaseContext);
  const {
    signOut,
    fetchUsersOnce
  } = api;
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const staticusers = useSelector((state) => state.usersdata.users);

  const LogOut = () => {
    dispatch(signOut());
  };
  
  useEffect(() => {
    dispatch(fetchUsersOnce());
  }, [dispatch, fetchUsersOnce]);
  
  const [sampleModal,setSampleModAL]=useState(false)

  const handleSmapleModal=()=>{
    setSampleModAL(false)
   }
 const rootRef = useRef(null);


  const useStyles = makeStyles((theme) => ({
    nested: {
      paddingRight: theme.spacing(4),
    },
    pickupDropModals: {
      display: 'flex', 
      justifyContent: 'center', 
      gap: '20px', 
      alignItems: 'center', 
      width: '300px', 
      height: '200px', 
      borderRadius: '10px', 
      position: 'absolute', 
      backgroundColor: '#fff', 
      padding: '20px', 
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
      '@media(max-width: 1100px)': {
        margin: '15% 20%'
      },
      '@media(max-width: 800px)': {
        margin: '20% 15%'
      },
      '@media(max-width: 600px)': {
        margin: '20% 10%'
      },
      '@media(max-width: 530px)': {
        margin: '20% 0%',
        width: '350px',
        padding: '10px'
      }
    }
  }));

  const classes = useStyles();
  
  const [menuActive, setMenuActive] = useState([false, false, false, false]);
  

  const pageBackgroundColor=()=>{
      if(auth?.info?.profile?.dashboardColor) return auth?.info?.profile?.dashboardColor
      if(giveIdThenIwillGiveYouData(auth?.info?.profile?.carrierId)?.dashboardColor) return giveIdThenIwillGiveYouData(auth?.info?.profile?.carrierId)?.dashboardColor
      return "#01aac1"
  }

  const handleClick = (index) => {
    setMenuActive(prevMenuActive => {
      const temp = [...prevMenuActive];
      temp[index] = !temp[index];
      return temp;
    });
  };
  const arrowLeft = {
    position: 'absolute',
    left: 0
  };
  const arrowRight = {
    position: 'absolute',
    right: 0
  };
  
  let isAdmin = auth.info && auth.info.profile && auth.info.profile.usertype === 'admin';
  let isFleetAdmin = auth.info && auth.info.profile && auth.info.profile.usertype === 'fleetadmin';
  let isDispatcher = auth.info && auth.info.profile && auth.info.profile.usertype === 'dispatcher'&& !auth.info?.profile?.independent && auth.info?.profile?.carrierId;
 
  let isDispatcherForBroker = auth.info && auth.info.profile && auth.info.profile.usertype === 'dispatcher'&& !auth.info?.profile?.independent && auth.info?.profile?.brokerId;
  let isDispatcherForDispatcher = auth.info && auth.info.profile && auth.info.profile.usertype === 'dispatcher'&& !auth.info?.profile?.independent && auth.info?.profile?.dispatcherId;
 
  let isDispatcherCompany = auth.info && auth.info.profile && auth.info.profile.usertype === 'dispatcher' && auth.info?.profile?.independent;
  let isShipper = auth.info && auth.info.profile && auth.info.profile.usertype === 'shipper';
  
  const location = useLocation();
  const history = useHistory();
  useEffect(()=>{
    if ((location.pathname === '/my-carriers'||location.pathname === "/dispatch-agencies"||location.pathname === '/my-brokers')  && isDispatcher) {
      history.push('/')
    }
  }, [location.pathname])

  function isAgentFleetAdminOrDipatcherThenHasNoDriver(){
    if(!staticusers) return false
    if(!isFleetAdmin&&!isDispatcher) return false
    let driverExist=staticusers.filter(e=>{
       return e.usertype==='driver'&&(e.fleetadmin===auth.info.uid||e.fleetadmin===auth.info.profile?.carrierId)&&e.emailVerified&&e.approved
    }).length===0
    return  driverExist
 }

 const giveIdThenIwillGiveYouData=(id)=>{
  if(!staticusers) return null
  const targetUser =staticusers.filter(e=>{
   return e.id===id
})
  if(targetUser.length>0) return targetUser[0]
  return null
}


const [ownerData,setOwnerData] =useState(null)

useEffect(()=>{
  if(auth.info?.profile?.brokerId){
       const targetData = giveIdThenIwillGiveYouData(auth.info?.profile?.brokerId)
       setOwnerData(targetData||null)
  } 
  else if(auth.info?.profile?.dispatcherId){
   const targetData = giveIdThenIwillGiveYouData(auth.info?.profile?.dispatcherId)
   setOwnerData(targetData||null)
   } 
   else if(auth.info?.profile?.carrierId){
     const targetData = giveIdThenIwillGiveYouData(auth.info?.profile?.carrierId)
     setOwnerData(targetData||null)
   } 
   else{
    setOwnerData({...auth.info?.profile,id:auth?.info?.uid})
   }

},[auth])

const isSupperAdmin=()=>{
  return giveIdThenIwillGiveYouData(auth?.info?.profile?.carrierId)?.specialAdmin || (auth.info?.profile?.specialAdmin && isFleetAdmin)
}

const isSpecialAdmin=()=>{
     
  return auth.info?.profile?.specialAdmin || giveIdThenIwillGiveYouData(auth.info?.profile?.carrierId)?.specialAdmin
}
 return (
    <>
      <div style={{overflow:"scroll", scrollbarWidth: 'none', msOverflowStyle: 'none',minHeight:"100vh",display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
         <div>
            <div style={{ flexDirection:"column",display: 'flex', backgroundColor: "white", justifyContent:'center', alignItems: 'center', marginTop: 20, height: '30vh'}}>
                {ownerData?.profile_image?
                    <img src = {ownerData?.profile_image} alt='Profile' loading='lazy'
                    style={{width:150, height:150, borderRadius: '50%',marginBottom:20}}/>
                     :
                  <img style={{ width: 'fit-content', height: '40px',objectFit:'cover', marginTop: '20px', marginBottom: '20px',}} src={require("../assets/img/logo_new.png").default} alt="Logo" />
                }
              
              <Box style={{fontSize:"18px",fontWeight:700,opacity:0.4,lineBreak:"anywhere",padding:5}}>
                {ownerData?.selectedcountry?.label}
              </Box>
            </div>
              {/* <Divider /> */}
              <MenuList>

              {isFleetAdmin||isDispatcher||isShipper||isDispatcherCompany||isDispatcherForBroker||isDispatcherForDispatcher ?
                  <MenuItem disabled={isAgentFleetAdminOrDipatcherThenHasNoDriver()} component={Link} to="/fleetmap"  style={{ color: location.pathname === '/fleetmap' ? pageBackgroundColor() : ''}} onClick={handleDrawerToggle}>
                    <ListItemIcon>
                      <MapIcon style={{color: location.pathname === '/fleetmap' ? pageBackgroundColor() : ''}} />
                    </ListItemIcon>
                    <Typography variant="inherit">{isShipper?'My Network Fleet': 'Fleet Map'}</Typography>
                  </MenuItem>
                  : null}

                <MenuItem disabled={isAgentFleetAdminOrDipatcherThenHasNoDriver()}  component={Link} to="/bookings"  style={{ color: location.pathname === '/bookings' ? pageBackgroundColor() : ''}} onClick={handleDrawerToggle}>
                  <ListItemIcon>
                    <HistoryOutlined style={{color: location.pathname === '/bookings' ? pageBackgroundColor() : ''}} />
                  </ListItemIcon>
                  <Typography variant="inherit">Manage Bookings</Typography>
                </MenuItem>

                {isAdmin||isDispatcher||isFleetAdmin||isDispatcherCompany || isDispatcherForDispatcher?
                  <MenuItem disabled={isAgentFleetAdminOrDipatcherThenHasNoDriver()}  component={Link} to="/addbookings" onClick={handleDrawerToggle} style={{color: location.pathname === '/addbookings' ? pageBackgroundColor() : ''}}>
                    <ListItemIcon>
                      <ContactPhoneIcon style={{color: location.pathname === '/addbookings' ? pageBackgroundColor() : ''}}/>
                    </ListItemIcon>
                    <Typography variant="inherit">Manual Booking</Typography>
                  </MenuItem>
                  : null}

            {isShipper||isAdmin||isDispatcherForBroker ?
                  <MenuItem component={Link} to="/my-board"  style={{ color: location.pathname === '/my-board' ? pageBackgroundColor() : ''}} onClick={handleDrawerToggle}>
                    <ListItemIcon>
                      <TableChartIcon style={{color: location.pathname === '/my-board' ? pageBackgroundColor() : ''}} />
                    </ListItemIcon>
                    <Typography variant="inherit">My Loads</Typography>
                  </MenuItem>
                  : null}

                {isFleetAdmin||isDispatcher||isDispatcherCompany||isDispatcherForDispatcher ?
                  <MenuItem component={Link} to="/drivers"  style={{ color: location.pathname === '/drivers' ? pageBackgroundColor() : ''}} onClick={handleDrawerToggle}>
                    <ListItemIcon>
                      <AirlineSeatReclineNormalIcon style={{color: location.pathname === '/drivers' ? pageBackgroundColor() : ''}} />
                    </ListItemIcon>
                    <Typography variant="inherit">Manage Drivers</Typography>
                  </MenuItem>
                  : null}

               {(isFleetAdmin && !isSupperAdmin())||isAdmin||(isDispatcher && !isSupperAdmin())||isDispatcherCompany || isDispatcherForDispatcher?
                    <MenuItem disabled={isAgentFleetAdminOrDipatcherThenHasNoDriver()} component={Link} to="/private-board"  style={{ color: location.pathname === '/private-board' ? pageBackgroundColor() : ''}} onClick={handleDrawerToggle}>
                      <ListItemIcon>
                        <DepartureBoardIcon style={{color: location.pathname === '/private-board' ? pageBackgroundColor() : ''}} />
                      </ListItemIcon>
                      <Typography variant="inherit">Private Load Board</Typography>
                    </MenuItem>
                  : null}

                {isFleetAdmin||isShipper||isDispatcherCompany ?
                  <MenuItem component={Link} to="/internal-reps" style={{ color: location.pathname === '/internal-reps' ? pageBackgroundColor() : ''}} onClick={handleDrawerToggle}>
                    <ListItemIcon style={{}}>
                      <GroupWorkIcon style={{ color: location.pathname === '/internal-reps' ? pageBackgroundColor() : ''}} />
                    </ListItemIcon>
                    <Typography variant="inherit">{isShipper?'My Agents': isSpecialAdmin ? "My Agents" : "My Dispatchers"}</Typography>
                  </MenuItem>
                  : null}

                {(isFleetAdmin && !isSupperAdmin())||isDispatcherCompany?
                  <MenuItem component={Link} to="/my-brokers"  style={{ color: location.pathname === '/my-brokers' ? pageBackgroundColor() : ''}} onClick={handleDrawerToggle}>
                    <ListItemIcon>
                      <Diversity2Icon style={{color: location.pathname === '/my-brokers' ? pageBackgroundColor() : ''}} />
                    </ListItemIcon>
                    <Typography variant="inherit">Brokers/ F.Forwarders</Typography>
                  </MenuItem>
                  : null}

                  {isShipper||isDispatcherCompany ?
                  <MenuItem component={Link} to="/my-carriers"  style={{ color: location.pathname === '/my-carriers' ? pageBackgroundColor() : ''}} onClick={handleDrawerToggle}>
                    <ListItemIcon>
                      <LocalShipping style={{color: location.pathname === '/my-carriers' ? pageBackgroundColor() : ''}} />
                    </ListItemIcon>
                    <Typography variant="inherit">Freight Carriers</Typography>
                  </MenuItem>
                  : null}


                {auth.info?.profile?.selectedcountry?.code === 'US'&&(isShipper||isFleetAdmin||isAdmin) ?
                  <MenuItem component={Link} to="/dispatch-agencies"  style={{ color: location.pathname === '/dispatch-agencies' ? pageBackgroundColor() : ''}} onClick={handleDrawerToggle}>
                    <ListItemIcon>
                      <BusinessIcon style={{color: location.pathname === '/dispatch-agencies' ? pageBackgroundColor() : ''}} />
                    </ListItemIcon>
                    <Typography variant="inherit">{isFleetAdmin?"Independent Dispatch":"Independent Dispatch"} </Typography>
                  </MenuItem>
                  : null}

               { isFleetAdmin&&isSupperAdmin() ?
                  <MenuItem component={Link} to="/my-routes"  style={{ color: location.pathname === '/my-routes' ? pageBackgroundColor() : ''}} onClick={handleDrawerToggle}>
                    <ListItemIcon>
                      <RouteIcon style={{color: location.pathname === '/my-routes' ? pageBackgroundColor() : ''}} />
                    </ListItemIcon>
                    <Typography variant="inherit"> My Routes </Typography>
                  </MenuItem>
                  : null}

                { isSupperAdmin() ?
                  <MenuItem component={Link} to="/customers"  style={{ color: location.pathname === '/customers' ? pageBackgroundColor() : ''}} onClick={handleDrawerToggle}>
                    <ListItemIcon>
                      <BusinessIcon style={{color: location.pathname === '/customers' ? pageBackgroundColor() : ''}} />
                    </ListItemIcon>
                    <Typography variant="inherit"> Customers </Typography>
                  </MenuItem>
                  : null}
                  
                  
                {isAdmin ?
                <div onClick={handleDrawerToggle}>
                  <MenuItem button onClick={() => {
                    handleClick(1)
                  }} component='div'>
                    <ListItemIcon>
                      <PeopleIcon />
                    </ListItemIcon>
                    <Typography>{t('users_title')}</Typography>
                    {menuActive[1] ?
                      <ListItemIcon style={isRTL === 'rtl' ? arrowLeft : arrowRight}>
                        <KeyboardArrowDownIcon style={{ direction: 'rtl' }} />
                      </ListItemIcon>
                      :
                      <ListItemIcon style={isRTL === 'rtl' ? arrowLeft : arrowRight}>
                        <KeyboardArrowLeftIcon />
                      </ListItemIcon>
                    }
                  </MenuItem>
                </div>
                  : null}
                <Collapse in={menuActive[1]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {isAdmin ?
                      <ListItem button className={classes.nested} component={Link} to="/shippers" onClick={handleDrawerToggle} style={{ color: location.pathname === '/shippers' ? pageBackgroundColor() : '', paddingLeft: '25px'}} >
                        <ListItemIcon>
                          <EmojiPeopleIcon style={{ color: location.pathname === '/shippers' ? pageBackgroundColor() : ''}} />
                        </ListItemIcon>
                        <Typography variant="inherit">{t('shippers')}</Typography>
                      </ListItem>
                      : null}
                    {isAdmin ?
                      <ListItem button className={classes.nested} component={Link} to="/drivers" onClick={handleDrawerToggle} style={{ color: location.pathname === '/drivers' ? pageBackgroundColor() : '', paddingLeft: '25px'}} >
                        <ListItemIcon>
                          <AirlineSeatReclineNormalIcon style={{ color: location.pathname === '/drivers' ? pageBackgroundColor() : ''}} />
                        </ListItemIcon>
                        <Typography variant="inherit">Manage Fleet</Typography>
                      </ListItem>
                      : null}
                    {isAdmin ?
                      <ListItem button className={classes.nested} component={Link} to="/fleetadmins" onClick={handleDrawerToggle} style={{ color: location.pathname === '/fleetadmins' ? pageBackgroundColor() : '', paddingLeft: '25px'}} >
                        <ListItemIcon>
                          < GroupAddIcon style={{ color: location.pathname === '/fleetadmins' ? pageBackgroundColor() : ''}} />
                        </ListItemIcon>
                        <Typography variant="inherit">{t('fleetadmins')}</Typography>
                      </ListItem>
                      : null}
                      {isAdmin ?
                      <ListItem button className={classes.nested} component={Link} to="/internal-reps" onClick={handleDrawerToggle} style={{ color: location.pathname === '/internal-reps' ? pageBackgroundColor() : '', paddingLeft: '25px'}} >
                        <ListItemIcon>
                          < GroupAddIcon style={{ color: location.pathname === '/internal-reps' ? pageBackgroundColor() : ''}} />
                        </ListItemIcon>
                        <Typography variant="inherit">Internal Dispatch</Typography>
                      </ListItem>
                      : null}
                    {isAdmin ?
                      <ListItem button className={classes.nested} component={Link} to="/alladmin" onClick={handleDrawerToggle} style={{ color: location.pathname === '/alladmin' ? pageBackgroundColor() : '', paddingLeft: '25px'}} >
                        <ListItemIcon>
                          < SupervisorAccountIcon style={{ color: location.pathname === '/alladmin' ? pageBackgroundColor() : ''}} />
                        </ListItemIcon>
                        <Typography variant="inherit">{t('alladmin')}</Typography>
                      </ListItem>
                      : null}

                  </List>
                </Collapse>

                {isAdmin ? 
                  <MenuItem component={Link} to="/trailertypes" onClick={handleDrawerToggle} style={{ color: location.pathname === '/trailertypes' ? pageBackgroundColor() : ''}} >
                    <ListItemIcon>
                      <CarIcon style={{ color: location.pathname === '/trailertypes' ? pageBackgroundColor() : ''}} />
                    </ListItemIcon>
                    <Typography variant="inherit">{t('car_type')}</Typography>
                  </MenuItem>
                  : null}

                {isAdmin ? 
                  <MenuItem component={Link} to="/routeTypes" onClick={handleDrawerToggle} style={{color: location.pathname === '/routeTypes' ? pageBackgroundColor() : ''}}>
                    <ListItemIcon>
                      <RouteIcon style={{color: location.pathname === '/routeTypes' ? pageBackgroundColor() : ''}}/>
                    </ListItemIcon>
                    <Typography variant="inherit">Route Types</Typography>
                  </MenuItem>
                  : null}

                {isAdmin ?
                  <MenuItem component={Link} to="/cancelreasons" onClick={handleDrawerToggle} style={{ color: location.pathname === '/cancelreasons' ? pageBackgroundColor() : ''}} >
                    <ListItemIcon>
                      <CancelScheduleSendIcon style={{ color: location.pathname === '/cancelreasons' ? pageBackgroundColor() : ''}} />
                    </ListItemIcon>
                    <Typography variant="inherit">{t('cancellation_reasons')}</Typography>
                  </MenuItem>
                  : null}


                {isAdmin ?
                <div onClick={handleDrawerToggle}>
                  <MenuItem button onClick={() => {
                    handleClick(3)
                  }} component='div'>
                    <ListItemIcon>
                      <SettingsIcon />
                    </ListItemIcon>
                    <Typography>{t('settings_title')}</Typography>
                    {menuActive[3] ?
                      <ListItemIcon style={isRTL === 'rtl' ? arrowLeft : arrowRight}>
                        <KeyboardArrowDownIcon />
                      </ListItemIcon>
                      :
                      <ListItemIcon style={isRTL === 'rtl' ? arrowLeft : arrowRight}>
                        <KeyboardArrowLeftIcon />
                      </ListItemIcon>
                    }
                  </MenuItem>
                </div>
                  : null}
                <Collapse in={menuActive[3]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {isAdmin ?
                      <ListItem button className={classes.nested} component={Link} to="/appinfo" onClick={handleDrawerToggle} style={{ color: location.pathname === '/appinfo' ? pageBackgroundColor() : '', paddingLeft: '25px'}} >
                        <ListItemIcon>
                          <PhoneIphoneIcon style={{ color: location.pathname === '/appinfo' ? pageBackgroundColor() : ''}} />
                        </ListItemIcon>
                        <Typography variant="inherit">{t('app_info')}</Typography>
                      </ListItem>
                      : null}
                    {isAdmin ?
                      <ListItem button className={classes.nested} component={Link} to="/settings" onClick={handleDrawerToggle} style={{ color: location.pathname === '/settings' ? pageBackgroundColor() : '', paddingLeft: '25px'}} >
                        <ListItemIcon>
                          <SettingsApplicationsIcon style={{ color: location.pathname === '/settings' ? pageBackgroundColor() : ''}} />
                        </ListItemIcon>
                        <Typography variant="inherit">{t('general_settings')}</Typography>
                      </ListItem>
                      : null}
                    {isAdmin ?
                      <ListItem button className={classes.nested} component={Link} to="/languagesetting" onClick={handleDrawerToggle} style={{ color: location.pathname === '/languagesetting' ? pageBackgroundColor() : '', paddingLeft: '25px'}} >
                        <ListItemIcon>
                          <LanguageIcon style={{ color: location.pathname === '/languagesetting' ? pageBackgroundColor() : ''}} />
                        </ListItemIcon>
                        <Typography variant="inherit">{t('language')}</Typography>
                      </ListItem>
                      : null}
                  </List>
                </Collapse>

                {/* {isAdmin ?
                  <MenuItem component={Link} to="/promos">
                    <ListItemIcon>
                      <OfferIcon />
                    </ListItemIcon>
                    <Typography variant="inherit">{t('promo')}</Typography>
                  </MenuItem>
                  : null} */}
                {isAdmin ?
                  <MenuItem component={Link} to="/notifications" onClick={handleDrawerToggle} style={{ color: location.pathname === '/notifications' ? pageBackgroundColor() : ''}} >
                    <ListItemIcon>
                      <NotifyIcon style={{ color: location.pathname === '/notifications' ? pageBackgroundColor() : ''}} />
                    </ListItemIcon>
                    <Typography variant="inherit">{t('push_notification_title')}</Typography>
                  </MenuItem>
                  : null}
                {/* {!(isAdmin || isFleetAdmin) ?
                <MenuItem component={Link} to="/userwallet">
                  <ListItemIcon>
                    <AccountBalanceWalletIcon />
                  </ListItemIcon>
                  <Typography variant="inherit">{t('my_wallet_tile')}</Typography>
                </MenuItem>
                :null} */}
                {/* <MenuItem component={Link} to="/profile"  style={{color: location.pathname === '/profile' ? pageBackgroundColor() : ''}} onClick={handleDrawerToggle}>
                  <ListItemIcon>
                    <AccountCircleIcon style={{color: location.pathname === '/profile' ? pageBackgroundColor() : ''}} />
                  </ListItemIcon>
                  <Typography variant="inherit">{t('profile')}</Typography>
                </MenuItem>
                <MenuItem onClick={()=>setSampleModAL(true)}>
                  <ListItemIcon>
                    <ExitIcon />
                  </ListItemIcon>
                  <Typography variant="inherit">{t('logout')}</Typography>
                </MenuItem> */}


        
              </MenuList>   
              

         </div>

          <div style={{display:"flex",justifyContent:"center",alignItems:"center",background:auth?.info?.profile?.dashboardColor||pageBackgroundColor(),color:"white"}}>
            <MenuItem  component={Link} to="/"  style={{ color: location.pathname === '/' ? pageBackgroundColor() : ''}} onClick={handleDrawerToggle}>
              <ListItemIcon>
                <ExitToAppIcon style={{color: 'white'}} />
              </ListItemIcon>
              <Typography variant="inherit">Exit Dashboard</Typography>
            </MenuItem>

          </div>
          
      </div>
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={sampleModal}
        onClose={handleSmapleModal}
        className={classes.modal}
        container={() => rootRef.current}
        style={{
          overflow: "auto",
          height: "100vh",
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Grid
          container
          spacing={1}
          className={classes.pickupDropModals}
          style={{ display: 'flex', flexDirection: 'column',lineBreak:"anywhere" }}
        >
          <div style={{fontSize: '20px', color: '#01aac1'}}>Are you sure do you want to log out ?</div>
          <div style={{display: 'flex', gap: '10px'}}>
            <button onClick={LogOut} style={{backgroundColor: '#01aac1', borderRadius: '10px', border: '0', padding: '10px 30px', cursor: 'pointer', color: '#fff', width: '80px'}}>Yes</button>
            <button onClick={()=>setSampleModAL(false)} style={{backgroundColor: '#01aac1', borderRadius: '10px', border: '0', padding: '10px 30px', cursor: 'pointer', color: '#fff', width: '80px'}}>No</button>
          </div>
        </Grid>
      </Modal>
    </>
  );
}

export default AppMenu;