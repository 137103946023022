import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from "assets/jss/material-kit-react/views/staticPages.js";

import HeroImg from '../assets/img/BG_HERO_DESIGN.png';
import styled from "styled-components";
const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function AboutUs(props) {

  const classes = useStyles();
  const { ...rest } = props;


  return (
    <div>
      <Header
        color="landing"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      {/* <Parallax small filter image={require("assets/img/background.png").default} className={classes.aboutUsParallax} /> */}
       
      <Div12 style={{
        backgroundImage: `url(${HeroImg})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        //  backgroundRepeat: 'repeat'
      }}>
        <div className={classes.container} style={{color:"white"}}>
            {/* <h2 style={{textAlign:'center',margin: "0px 0 40px 0",color: "white"}}>
               About Freight Canvas
            </h2> */}
            <div style={{font:'400 26px Poppins, sans-serif', color:"#d4d4d4", marginTop:"60px"}}>
                <P>
                Freight Canvas brings a new approach to logistics in emerging markets, helping businesses move beyond fragmented, resource-draining supply chains. In many regions, companies rely on private logistics networks that create inefficiencies, reduce productivity, and ultimately impact economic growth. Freight Canvas eliminates these inefficiencies by connecting enterprises to a streamlined, technology-powered logistics network that transforms their supply chain capabilities.                </P>
                <P>
                We start by equipping fleet owners with IoT-enabled asset trackers and a comprehensive fleet management platform at no cost. This technology allows carriers to monitor their fleets in real-time and enhances their ability to serve more customers. With their permission, Freight Canvas then provides enterprises instant access to current data on available carrier capacity, including up-to-date locations and real-time load availability.                </P>
                <P>
                Beyond managing fleets and freight, Freight Canvas fosters a digital environment where logistics professionals connect and work together, building trusted partnerships that improve efficiency. By empowering carriers, brokers, and forwarders with reliable tools and data, we help the entire logistics industry create shared opportunities, optimize operations, and enhance service quality for customers across industries.                </P>
                <P>
                Finally, Freight Canvas customizes the experience for each organization, based on their unique needs. Whether you're a trucking company, a freight broker, or a last-mile delivery provider, our platform scales to meet your specific use case, maximizing both efficiency and growth.                </P>
            </div>

        </div>

      </Div12>
    

      <Footer/>
    </div>
  );
}

const P=styled.p`
 font:300 21px Poppins, sans-serif;
 color: #d4d4d4;
 line-height:1.5;
 margin-bottom: 40px;
`

const Div12 = styled.div`
  justify-content: center;
  align-items: center;
  z-index: 10;
  display: flex;
  width: 100%;
  height:100%;
  min-height:80vh;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 20px;
  }
`;