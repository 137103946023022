import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { useDispatch, useSelector } from "react-redux";
import { FirebaseContext } from 'common';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { List, ListItem, ListItemText, makeStyles } from '@material-ui/core';
import { Apartment, Assignment, BusinessCenter, Commute, DirectionsCar, EventAvailable, FiberManualRecord, Language, LocalShipping, LocationCity, LocationOn, Public, RecentActors, Schedule, Streetview, SupervisedUserCircle, Telegram } from '@material-ui/icons';
import { IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useLocation } from 'react-router-dom';
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import PhoneIcon from '@mui/icons-material/Phone';

const useStyles = makeStyles(() => ({
  userEachData: {
    width: 430,
    borderRadius: '10px',
    padding: '18px 10px',
    margin: '18px auto',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',
    lineBreak:"anywhere",
    '@media(max-width: 680px)': {
      width: 310
    },
    '@media(max-width: 600px)': {
      width: 400
    },
    '@media(max-width: 450px)': {
      width: '80%'
    },
    '@media(max-width: 400px)': {
      width: '80%',
    },
  },
}))

export default function EachUserProfile(props) {

  const users = useSelector((state) => state.usersdata.users);
  const params = useParams()
  const { userId } = params

  const classes = useStyles()
  const dispatch = useDispatch();

  const loadBoardData = useSelector(state => state.loadBoardData.loadboard);
  const allUsers = useSelector(state => state.usersdata.staticusers)
  const { api } = useContext(FirebaseContext);
  const auth = useSelector(state => state.auth);
  const [userData, setUserData] = useState(null)

  const {
    fetchLoadBoardBookings,
    fetchUsersActiveHistory
  } = api;

  useEffect(() => {
    dispatch(fetchLoadBoardBookings(userId));
  }, [dispatch, fetchLoadBoardBookings]);

  function giveMeIdThenIwillGiveYouName(id) {
    if (!users) return ""

    let targetUser = users.filter(e => {
      return e.id === id
    })
    if (targetUser.length === 0) return ""
    return targetUser[0].firstName + " " + targetUser[0].lastName
  }

  const giveIdThenIwillGiveYouData=(id)=>{
    const targetUser =users.filter(e=>{
     return e.id===id
  })
    if(targetUser.length>0) return targetUser[0]
    return null

}

  // const posts = [
  //   { id: 1, customer: 101, createdAt: "2024-03-12" },
  //   { id: 20, customer: 101, createdAt: "2024-03-13" },
  //   { id: 21, customer: 101, createdAt: "2024-03-9" },
  //   { id: 210, customer: 101, createdAt: "2024-03-27" },
  //   { id: 212, customer: 101, createdAt: "2024-03-26" },
  //   { id: 2, customer: 101, createdAt: "2024-03-28" },
  //   { id: 3, customer: 101, createdAt: "2024-03-28" },
  //   { id: 4, customer: 101, createdAt: "2024-04-03" },
  //   { id: 5, customer: 101, createdAt: "2024-04-06" },
  //   { id: 50, customer: 101, createdAt: "2024-04-02" },
  //   { id: 51, customer: 101, createdAt: "2024-05-21" },
  //   { id: 52, customer: 101, createdAt: "2024-05-03" },
  //   // Add more posts...
  // ];
 function calculateTotalLoadPostPerWeek(posts){

      if(!posts||posts.length===0) return 0

      const userPostsCount = {};
      const userPostsRatio = {};
      
      posts.forEach(post => {
        const week = getWeekNumber(new Date(post.createdAt));
      
        if (!userPostsCount[post.customer]) {
          userPostsCount[post.customer] = {};
        }
      
        if (!userPostsCount[post.customer][week]) {
          userPostsCount[post.customer][week] = 0;
        }
      
        userPostsCount[post.customer][week]++;
      });
      
      function getWeekNumber(date) {
        const onejan = new Date(date.getFullYear(), 0, 1);
        return Math.ceil(((date - onejan) / 86400000 + onejan.getDay() + 1) / 7);
      }
  
      Object.keys(userPostsCount).forEach(customer => {
        const weeks = Object.keys(userPostsCount[customer]);
        const totalPosts = weeks.reduce((sum, week) => sum + userPostsCount[customer][week], 0);
        const averagePosts = Math.ceil((totalPosts / weeks.length*10))/10;
        userPostsRatio[customer] = averagePosts;
      });
      
      return userPostsRatio[posts[0].customer]
    }

  function isUserAdmin(data){
    
     return data.usertype==='shipper' || data.usertype==='fleetadmin' || (data.usertype==='dispatcher' && data.independent)

  }

  const [userActivity,setUserActivity]=useState(null)
  const fetchData = async (userId) => {
    try {
      const res = await dispatch(fetchUsersActiveHistory(userId));
      if (res && res?.activeHistoryPerWeek) {
        setUserActivity(res.activeHistoryPerWeek);
      }
    } catch (error) {
    }
  };

  useEffect(()=>{
    if(userId){
      fetchData(userId)
    }
  },[userId])

  const [profile_image,set_profile_image] =useState(null)
  useEffect(() => {
    if (users) {
      let filterData = users.filter(e => {
        return e.id === userId
      })
      if (filterData.length > 0) {
        if(filterData[0]?.profile_image) set_profile_image(filterData[0]?.profile_image)

        let selectedData = {
        }
        if(!isUserAdmin(filterData[0])){
          selectedData["name"] = filterData[0].firstName + " " + filterData[0].lastName
        }
        if (filterData[0].companyName) {
          selectedData["CompanyName"] = filterData[0].companyName
          selectedData["mobile"] = filterData[0].mobile
        }
        selectedData['usertype'] = filterData[0].usertype

        if(filterData[0].selectedcountry&&filterData[0].selectedcountry?.label){
          selectedData['country']=filterData[0].selectedcountry?.label
        }
        // if(filterData[0].usertype!=='dispatcher'&&filterData[0].usertype!=='driver'){
        //   selectedData['loadsAvailable'] = loadBoardData ? loadBoardData.length : 0
        // }
        // else if(filterData[0].usertype==='dispatcher'&&filterData[0].independent){
        //   selectedData['loadsAvailable'] = loadBoardData ? loadBoardData.length : 0
        // }
       
        if (filterData[0].shopeName) {
          selectedData["shopeName"] = filterData[0]?.shopeName
          selectedData["mobile"] = filterData[0]?.mobile
        }
        if (filterData[0].TelegramId) {
          selectedData["tg_username"] = filterData[0]?.TelegramId?.username
        }
        if (filterData[0].channelLink) {
          selectedData["ChannelLink"] = filterData[0].channelLink
        }
        if (filterData[0].website) {
          selectedData["website"] = filterData[0].website
        }
        
        if (filterData[0].SharedCarriers) {
          let availebleCarriers = filterData[0].SharedCarriers.map(e => {
            if (e.approved && (e.aprovedByBroker || e.aprovedByCarrier)) {
              return { name: giveMeIdThenIwillGiveYouName(e.id), id: e.id }
            }
            else return;
          })
          const definedCarriers = availebleCarriers.filter(carrier => carrier !== undefined)
          if (definedCarriers.length > 0) {
            selectedData["SharedCarriers"] = definedCarriers.length
          }
          else {
            selectedData["SharedCarriers"] = 'None'
          }
        }
        else if(filterData[0].usertype !== 'fleetadmin'){
          selectedData["SharedCarriers"] = 'None'
        }

        if (filterData[0].SharedBrokers) {
          let availebleBrokers = filterData[0].SharedBrokers.map(e => {
            if (e.approved && e.aprovedByBroker) {
              return { name: giveMeIdThenIwillGiveYouName(e.id), id: e.id }
            }
            else return;
          })
          const definedBrokers = availebleBrokers.filter(carrier => carrier !== undefined)
          if (definedBrokers.length > 0) {
            selectedData["SharedBrokers"] = definedBrokers.length
          }
          else {
            selectedData["SharedBrokers"] = 'None'
          }
        }
        else if(filterData[0].usertype !== 'shipper'){
          selectedData["SharedBrokers"] = 'None'
        }

        if (filterData[0].SharedDispatcher) {
            if(filterData[0].usertype === 'shipper'){
              let availableDispatchers = filterData[0].SharedDispatcher.map(e => {
                if (e.approved && (e.aprovedByBroker || e.aprovedByCarrier)) {
                  return giveIdThenIwillGiveYouData(e.id).SharedCarriers
                }
              });
              if( availableDispatchers[0]){
                  const definedCarriers = availableDispatchers[0].filter(carrier => carrier !== undefined && carrier?.approved && (carrier?.aprovedByBroker || carrier?.aprovedByCarrier))    
                  // console.log(definedCarriers,selectedData["SharedCarriers"],availableDispatchers);
    
                  if(definedCarriers.length>0){
                      if( !selectedData["SharedCarriers"] || selectedData["SharedCarriers"] === 'None'){
                      selectedData["SharedCarriers"]= definedCarriers.length
                      }
                      else{
                        selectedData["SharedCarriers"] =  definedCarriers.length +  selectedData["SharedCarriers"]
                      }
                  }

              }

            }
            else{
                let availableDispatchers = filterData[0].SharedDispatcher.map(e => {
                  if (e.approved && (e.aprovedByBroker || e.aprovedByCarrier)) {
                    return giveIdThenIwillGiveYouData(e.id).SharedBrokers
                  }
                });

                if(availableDispatchers[0]){
                      const definedBrokers = availableDispatchers[0].filter(broker => broker !== undefined && broker?.approved && (broker?.aprovedByBroker || broker?.aprovedByCarrier))      
                      if(definedBrokers.length>0){
                          if( !selectedData["SharedBrokers"] || selectedData["SharedBrokers"] === 'None'){
                          selectedData["SharedBrokers"]= definedBrokers.length
                          }
                          else{
                            selectedData["SharedBrokers"] =  definedBrokers.length +  selectedData["SharedBrokers"]
                          }
                      }

                }
  
            }

        }
        
        else if(filterData[0].usertype !== 'dispatcher'){
          selectedData["SharedDispatcher"] = 'None'
        }
        if (filterData[0].usertype === "fleetadmin") {
          selectedData["dotNumber"] = filterData[0]?.carrierData?.dotNumber||""
          selectedData["legalName"] = filterData[0]?.carrierData?.legalName||""
          selectedData["phyCity"] = filterData[0]?.carrierData?.phyCity||""
          selectedData["phyCountry"] = filterData[0]?.carrierData?.phyCountry||""
          selectedData["phyState"] = filterData[0]?.carrierData?.phyState||""
          selectedData["phyStreet"] = filterData[0]?.carrierData?.phyStreet||""
          let carrierDrivers = users.filter(e => {
            if (e.approved && e.emailVerified) {
              return e.fleetadmin === userId
            }
          })
          selectedData["drivers"] = carrierDrivers.length
          let carrierDispatchers = users.filter(e => {
            if (e.approved && e.emailVerified) {
              return e.carrierId === userId
            }
          })
          selectedData["dispatchers"] = carrierDispatchers.length
        }

        if (filterData[0].usertype === "driver") {
          let carrier = users.filter(e => {
            return e.id === filterData[0].fleetadmin
          })
          if (carrier.length > 0) {
             selectedData["fromOwner"] = {name:carrier[0].firstName + " " + carrier[0].lastName,id:filterData[0].fleetadmin}
            }

        }

        if (filterData[0].usertype === "dispatcher" && !filterData[0].independent) {
          let owner = users.filter(e => {
            return e.id === filterData[0].carrierId || e.id === filterData[0].brokerId || e.id === filterData[0].dispatcherId 
          })

          if (owner.length > 0) {
             selectedData["fromOwner"] = {name:owner[0].firstName + " " + owner[0].lastName,id:filterData[0]?.carrierId || filterData[0].brokerId || filterData[0].dispatcherId}
            }

        }

        if (filterData[0].usertype === 'dispatcher' && filterData[0].independent) {
          selectedData["usertype"] = 'Dispatch Agency'
        }
        if (filterData[0].usertype === 'dispatcher' && !filterData[0].independent) {
          selectedData["usertype"] = 'Internal Agent '
        }
        if (filterData[0].usertype === "shipper") {
          selectedData['loadsPerWeek'] = calculateTotalLoadPostPerWeek(loadBoardData)
          selectedData["usertype"] = selectedData["actionType"]==='forwarder'? 'Freight Forwarder': 'Freight Broker' 
          
        }

        if (filterData[0].usertype === "fleetadmin" || filterData[0].usertype === "shipper") {
            selectedData["activityHistory"] = userActivity?userActivity:0

        }   

        if (filterData[0].usertype === "fleetadmin") {
          selectedData["usertype"] = 'Carrier'

        }
        if (filterData[0].usertype === "customer") {
          selectedData["usertype"] = 'Customer'

        }

        if((filterData[0].usertype==='dispatcher'&& !filterData[0].independent)||filterData[0].usertype==='driver'){
          delete selectedData.SharedDispatcher
          delete selectedData.SharedBrokers
          delete selectedData.loadsPerWeek
          delete selectedData.SharedCarriers

        }   
        setUserData(selectedData)
      }
    }

  }, [users, userId, loadBoardData,userActivity])



  const [redirectedTo,setRedirectedTo]=useState('') 
  
  const location = useLocation();
  
  useEffect(()=>{
    if(location.search){
     const queryParams = new URLSearchParams(location.search);
     const redirected = queryParams.get('redirected');

     if(redirected){
      setRedirectedTo(redirected)
     }
     else {
      setRedirectedTo('')
      }
     
    }else {
      setRedirectedTo('')
      }
   
},[location.search])

  return (
    <div>
      {!userData ?
        <h2 style={{ textAlign: 'center', marginTop: '20%', color: '#01aac1' }}>No user found</h2>
        :
        <div>
          <IconButton onClick={() => props.history.push(`/${redirectedTo}`)}><ArrowBackIcon/></IconButton>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
             
              {profile_image?
                    <img src = {profile_image} alt='Profile' loading='lazy'
                    style={{width:180, height:180, borderRadius: '50%',marginBottom:20}}/>
                     :
                     <AccountCircleIcon style={{ fontSize: '170px', color: '#01aac1' }} />
               }
              
            </div>

          {/* {userActivity&&
                 <div className={classes.userEachData}style={{ display: 'flex', alignItems: 'center', gap: 10, fontSize: '16px' }}>
                      <LocalActivityIcon style={{ fontSize: '30px' }} /><span style={{ fontWeight: 'bold' }}>Activity: </span>{userActivity} Active Bookings per week
                 </div>
                } */}

          <div className={classes.usersDataContainer}>
            {Object.entries(userData).map(([key, value]) => (
              <div className={classes.userEachData} key={key}>
                {key === 'name' && <div style={{ display: 'flex', alignItems: 'center', gap: 10, fontSize: '16px' }}><RecentActors style={{ fontSize: '30px' }} /><span style={{ fontWeight: 'bold' }}> Name:</span> {value}</div>}
                {key === 'country' && <div style={{ display: 'flex', alignItems: 'center', gap: 10, fontSize: '16px' }}><FlagCircleIcon style={{ fontSize: '30px' }} /><span style={{ fontWeight: 'bold' }}> Country:</span> {value}</div>}
                {key === 'usertype' && <div style={{ display: 'flex', alignItems: 'center', gap: 10, fontSize: '16px' }}><SupervisedUserCircle style={{ fontSize: '30px' }} /><span style={{ fontWeight: 'bold' }}>Company Type:</span>  {value}</div>}
                {key === 'tg_username' && <div style={{ display: 'flex', alignItems: 'center', gap: 10, fontSize: '16px' }}><Telegram style={{ fontSize: '30px' }} /><span style={{ fontWeight: 'bold' }}>Telegram Username:</span> <a target='_blank' href={`https://web.telegram.org/k/#@${value}`}>@{value}</a></div>}
                {key === 'fromOwner' && <div style={{ display: 'flex', alignItems: 'center', gap: 10, fontSize: '16px' }}>
                   <LocalShipping style={{ fontSize: '30px' }} /><span style={{ fontWeight: 'bold' }}>From Owner: </span><a target='_blank' href={`/user/${userData?.fromOwner?.id}?redirected=user/${userId}`}>{value.name}</a></div>}

                {key === 'dotNumber' && <div style={{ display: 'flex', alignItems: 'center', gap: 10, fontSize: '16px' }}><Assignment style={{ fontSize: '30px' }} /><span style={{ fontWeight: 'bold' }}>Dot Number:</span> {value}</div>}
                {key === 'legalName' && <div style={{ display: 'flex', alignItems: 'center', gap: 10, fontSize: '16px' }}><AccountCircleIcon style={{ fontSize: '30px' }} /><span style={{ fontWeight: 'bold' }}>Legal Name:</span> {value}</div>}
                {key === 'phyCity' && <div style={{ display: 'flex', alignItems: 'center', gap: 10, fontSize: '16px' }}><LocationCity style={{ fontSize: '30px' }} /><span style={{ fontWeight: 'bold' }}>City:</span> {value}</div>}
                {key === 'phyCountry' && <div style={{ display: 'flex', alignItems: 'center', gap: 10, fontSize: '16px' }}><Public style={{ fontSize: '30px' }} /> <span style={{ fontWeight: 'bold' }}>Country (from DOT number):</span> {value}</div>}
                {key === 'phyState' && <div style={{ display: 'flex', alignItems: 'center', gap: 10, fontSize: '16px' }}><LocationOn style={{ fontSize: '30px' }} /><span style={{ fontWeight: 'bold' }}>State:</span> {value}</div>}
                {key === 'phyStreet' && <div style={{ display: 'flex', alignItems: 'center', gap: 10, fontSize: '16px' }}><Streetview style={{ fontSize: '30px' }} /><span style={{ fontWeight: 'bold' }}>Street:</span> {value}</div>}

                {key === 'drivers' && <div style={{ display: 'flex', alignItems: 'center', gap: 10, fontSize: '16px' }}><DirectionsCar style={{ fontSize: '30px' }} /><span style={{ fontWeight: 'bold' }}>Drivers:</span> {value}</div>}
                {key === 'dispatchers' && <div style={{ display: 'flex', alignItems: 'center', gap: 10, fontSize: '16px' }}><Commute style={{ fontSize: '30px' }} /><span style={{ fontWeight: 'bold' }}>Internal Dispatches: </span>{value}</div>}
                {key === 'loadsAvailable' && <div style={{ display: 'flex', alignItems: 'center', gap: 10, fontSize: '16px' }}><EventAvailable style={{ fontSize: '30px' }} /><span style={{ fontWeight: 'bold' }}>Loads Available: </span>{value}</div>}
                {key === 'loadsPerWeek' && <div style={{ display: 'flex', alignItems: 'center', gap: 10, fontSize: '16px' }}><Schedule style={{ fontSize: '30px' }} /><span style={{ fontWeight: 'bold' }}>Load Posts: </span>{value} Load per week</div>}
                {key === 'activityHistory' && <div style={{ display: 'flex', alignItems: 'center', gap: 10, fontSize: '16px' }}><LocalActivityIcon style={{ fontSize: '30px',color:"green" }} /><span style={{ fontWeight: 'bold' }}>Activity: </span>{value} Active Bookings per week</div>}

                {key === 'SharedDispatcher' && <div><span style={{ display: 'flex', alignItems: 'center', gap: 10, fontSize: '16px' }}><Commute style={{ fontSize: '30px' }} /><span style={{ fontWeight: 'bold', }}>Dispatch Agencies: </span>
                  {value === 'None' ? <span>None</span>:value}</span>
                  
                </div>}
                
                {key === 'SharedBrokers' &&
                   <div><span style={{ display: 'flex', alignItems: 'center', gap: 10, fontSize: '16px' }}><BusinessCenter style={{ fontSize: '30px' }} /><span style={{ fontWeight: 'bold', }}> Brokers They Work With:</span>
                     {value === 'None' ? <span>None</span>:value}</span>
                </div>}

                {key === 'SharedCarriers' && 
                    <div>
                      <span style={{ display: 'flex', alignItems: 'center', gap: 10, fontSize: '16px' }}><LocalShipping style={{ fontSize: '30px' }} /><span style={{ fontWeight: 'bold', }}>Carriers They Work With: </span>{value === 'None' ? <span>None</span>:value}</span>
                  
                </div>}


                
                {key === 'CompanyName' && <div style={{ display: 'flex', alignItems: 'center', gap: 10, fontSize: '16px' }}><Apartment style={{ fontSize: '30px' }} /><span style={{ fontWeight: 'bold' }}>Company Name: </span>{value}</div>}
                {key === 'shopeName' && <div style={{ display: 'flex', alignItems: 'center', gap: 10, fontSize: '16px' }}><Apartment style={{ fontSize: '30px' }} /><span style={{ fontWeight: 'bold' }}>Business Name: </span>{value}</div>}
                {key === 'mobile' && <div style={{ display: 'flex', alignItems: 'center', gap: 10, fontSize: '16px' }}><PhoneIcon style={{ fontSize: '30px' }} /><span style={{ fontWeight: 'bold' }}>Business Phone: </span>{value}</div>}
                {key === 'ChannelLink' && <div style={{ display: 'flex', alignItems: 'center', gap: 10, fontSize: '16px' }}><Telegram style={{ fontSize: '30px' }} /><span style={{ fontWeight: 'bold' }}>Telegram Channel Link: </span><a style={{ fontSize: '13px' }} target='_blank' href={`https://t.me/${value}`}>https://t.me/{value}</a></div>}
                {key === 'website' && 
                    <div style={{ display: 'flex', alignItems: 'center', gap: 10, fontSize: '16px' }}><Language style={{ fontSize: '30px' }} /><span style={{ fontWeight: 'bold' }}>Website: </span><a target='_blank' href={`https://${value}`}>https://{value}</a>
                    </div>}
               
              </div>
            ))}
          </div>
            

        </div>
      }
    </div>
  )
}
/**
 *    if(filterData[0].SharedCarriers&&filterData[0].SharedCarriers.length>0){
                      let availebleCarriers = filterData[0].SharedCarriers.map(e=>{
                        if (e.approved&&e.aprovedByBroker) {
                          return {name: giveMeIdThenIwillGiveYouName(e.id), id: e.id}
                        }
                        else return;
                      })
                      const definedCarriers = availebleCarriers.filter(carrier => carrier !== undefined)
                      if(definedCarriers.length>0) {
                        selectedData["SharedCarriers"]=definedCarriers
                      }
                      else{
                        selectedData["SharedCarriers"]='None'
                      }
                    }
                    
                    if(filterData[0].SharedBrokers&&filterData[0].SharedBrokers.length>0){
                      let availebleBrokers = filterData[0].SharedBrokers.map(e=>{
                        if (e.approved&&e.aprovedByBroker) {
                          return {name: giveMeIdThenIwillGiveYouName(e.id), id: e.id}
                        }
                        else return;
                      })
                      
                    const definedBrokers = availebleBrokers.filter(carrier => carrier !== undefined)
                    if(definedBrokers.length>0) {
                      selectedData["SharedBrokers"]=definedBrokers
                    }
                    else{
                      selectedData["SharedBrokers"]='None'
                    }
                    }
 */
/**
 * 
 *  if(filterData[0].SharedCarriers&&filterData[0].SharedCarriers.length>0){
                      let availebleCarriers = filterData[0].SharedCarriers.map(e=>{
                        if (e.approved&&e.aprovedByBroker) {
                          return {name: giveMeIdThenIwillGiveYouName(e.id), id: e.id}
                        }
                        else return;
                      })
                      const definedCarriers = availebleCarriers.filter(carrier => carrier !== undefined)
                      if(definedCarriers.length>0) {
                        selectedData["SharedCarriers"]=definedCarriers
                      }
                      else{
                        selectedData["SharedCarriers"]='None'
                      }
                    }

                    if(filterData[0].SharedBrokers&&filterData[0].SharedBrokers.length>0){
                      let availebleBrokers = filterData[0].SharedBrokers.map(e=>{
                        if (e.approved&&e.aprovedByBroker) {
                          return {name: giveMeIdThenIwillGiveYouName(e.id), id: e.id}
                        }
                        else return;
                      })
                      const definedBrokers = availebleBrokers.filter(carrier => carrier !== undefined)
                      if(definedBrokers.length>0) {
                        selectedData["SharedBrokers"]=definedBrokers
                      }
                      else{
                        selectedData["SharedBrokers"]='None'
                      }
                    }
 */